export const SET_LAYOUT = 'SET_LAYOUT'
export const SET_CHART = 'SET_CHART'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_PREFECTURES = 'SET_PREFECTURES'
export const LOG_OUT = 'LOG_OUT'
export const START_LOADING = 'START_LOADING'
export const END_LOADING = 'END_LOADING'
export const SET_PERMISSION = 'SET_PERMISSION'
export const SET_APP_UPDATE_TIME = 'SET_APP_UPDATE_TIME'
export const SET_UNREAD_NOTIFICATION = 'SET_UNREAD_NOTIFICATION'
export const SET_PROVINCE = 'SET_PROVINCE'
export const SET_DICTIONARIES = 'SET_DICTIONARIES'
export const SET_APPRAISE_OTHER = 'SET_APPRAISE_OTHER'
export const SET_APARTMENT = 'SET_APARTMENT'
export const SET_MAP_LOCATION = 'SET_MAP_LOCATION'
export const SET_MAP_FILTER = 'SET_MAP_FILTER'
export const SET_NAV_EXP = 'SET_NAV_EXP'
