let navigations = [];

const loadNavigations = async () => {
	const clientName = process.env.VUE_APP_CLIENT_NAME || 'Default';
	try {
		const config = await import(`@/config/navigations/navigations${clientName}.js`);
		navigations = config.navigations || [];
	} catch (error) {
		console.error('Error loading client config, falling back to default:', error);
	}
};

loadNavigations();

export { navigations };

