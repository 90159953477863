export default {
	real_estate: 'Bất động sản',
	// staff: 'Nhân viên',
	management: 'Quản lý khu vực',
	map: 'Bản đồ',
	unit_price: 'Đơn gía xây dựng',
	category: 'Danh mục',
	activate: 'Activate',
	active: 'Active',
	add: 'Add',
	add_more_gift_item: 'Add Gift Item',
	address: 'Address',
	admin: 'Admin',
	admin_list_page: 'List of admins',
	admin_name: 'Admin Name',
	admin_register: 'Create an admin',
	admin_update: 'Edit admin',
	administrator_management: 'Administrator',
	all: 'All',
	available_permission: 'Available permission',
	banner: 'Banner',
	banner_list_page: 'List of banners',
	banner_management: 'Banner',
	banner_register: 'Create a banner',
	banner_update_page: 'Edit banner',
	back: 'Trở lại',
	birthday: 'Birthday',
	browse_file: 'Browse file',
	cancel: 'Cancel',
	categories: 'Categories',
	category_code: 'Category Code',
	category_list_page: 'List of categories',
	category_register: 'Create a category',
	category_type: 'Category Type',
	category_update: 'Edit category',
	categorygift_list_page: 'List of category gift',
	categorygift_register: 'Create a category gift',
	categorygift_update: 'Edit category gift',
	change_password: 'Change password',
	choose_file: 'Choose file',
	choose_thumbnail: 'Choose thumbnail',
	city: 'City',
	code: 'Code',
	companies: 'Companies',
	company_code: 'Company code',
	company_list_page: 'List of Companies',
	company_register: 'Create an Company',
	company_update: 'Edit Company',
	confirm_password: 'Confirm Password',
	contact_list_page: 'List of contacts',
	contact_register: 'Create a contact',
	contact_update: 'Edit contact',
	content: 'Content',
	content_body: 'Content body',
	content_list_page: 'List of contents',
	content_management: 'Content',
	content_register: 'Create a content',
	content_title: 'Title',
	content_update: 'Edit content',
	content_url: 'Url',
	coupon_image: 'Coupon image',
	coupon_using_deadline: 'Coupon Using Deadline',
	coupon_using_guide: 'Coupon Using Guide',
	create_message_successfully: 'Create successfully',
	created_at: 'Created At',
	created_by: 'Created By',
	daily: 'daily',
	dashboard: 'Dashboard',
	dashboard_title_page: 'Dashboard',
	date: 'date',
	datetime: 'date time',
	deadline: 'Deadline',
	deadline_for_applying: 'Deadline for Applying',
	delete: 'Delete',
	delete_content: 'Bạn có chắc muốn xóa?',
	delete_header: 'Delete confirmation',
	delete_message_error: 'Delete error',
	delete_message_successfully: 'Delete successfully',
	description: 'Description',
	discounted_price: 'Coupon Discount Amount',
	display_name: 'Display name',
	display_order: 'Display Order',
	display_time: 'Display time',
	display_type: 'Display type',
	duration: 'Duration',
	edit: 'Edit',
	email: 'Email',
	end_time: 'Ngày kết thúc',
	error_400_content: 'Chúng tôi rất tiếc nhưng không tìm thấy trang bạn đang tìm',
	error_401_content: 'Chúng tôi rất tiếc nhưng bạn không được phép truy cập trang này',
	error_403_content: 'Chúng tôi rất tiếc nhưng bạn không có quyền truy cập trang này',
	error_404_content: 'Chúng tôi rất tiếc nhưng không tìm thấy trang bạn đang tìm',
	error_500_content: 'Rất tiếc, máy chủ của chúng tôi đã gặp lỗi nội bộ',
	error_503_content: 'Chúng tôi rất tiếc nhưng dịch vụ của chúng tôi hiện không khả dụng',
	error_happened_please_try_again: 'Quá trình tải lên gặp sự cố.',
	error_max_20_files_upload: 'Các tệp không được lớn hơn 20 tệp',
	error_title: 'Rất tiếc… Bạn vừa tìm thấy một trang lỗi',
	export_csv: 'Xuất dưới dạng tệp CSV',
	fax: 'Fax',
	file: 'file',
	first_name: 'First Name',
	freedial: 'Free dial',
	from: 'from',
	gender: 'Gender',
	gift_category_management: 'Gift Category',
	gift_item: 'Gift Item',
	gift_items: 'Gift Items',
	gift_using: 'Gift Using',
	gift_title: 'Gift Title',
	gift_using_history: 'Gift Using History',
	giveawaygift: 'Giveaway Gift',
	giveawaygift_list_page: 'List of give away gift',
	giveawaygift_register: 'Create a give away gift',
	giveawaygift_update: 'Edit give away gift',
	id: 'ID',
	image: 'Image',
	inactive: 'Inactive',
	is_active: 'Status',
	is_preview: 'Preview',
	last_name: 'Last Name',
	link_to_content: 'Link to content',
	link_to_url: 'Link to url',
	list: 'List',
	login: 'Đăng nhập',
	login_id: 'Login Id',
	login_password_placeholder: 'Password',
	login_remember_me: 'Remember Me',
	login_title: '<strong>Sign in</strong> to dashboard',
	login_username_placeholder: 'Username',
	login_welcome: 'Welcome, please use the form to sign-in.',
	login_wrong_username_password: 'Error!!! You have entered incorrect username or password.',
	logout: 'Log out',
	lottery_date: 'Lottery Date',
	lottery_drawing_date: 'Lottery Drawing Date',
	lottery_gift_category: 'Lottery Gift Category',
	lottery_gift_management: 'Lottery Gift',
	lotterygift: 'Lottery gift',
	lotterygift_list_page: 'List of lottery gift',
	lotterygift_register: 'Lottery Gift Add',
	lotterygift_update: 'Lottery Gift Edit',
	main_image: 'Main Image',
	media_list_page: 'Media List',
	media_title: 'Media title',
	message_body: 'Message Body',
	message_error: 'Error',
	message_success: 'Success',
	minimum_total_price: 'Min Limit for Applying Coupon',
	monthly: 'monthly',
	more_info: 'More info',
	name: 'Name',
	news: 'News',
	news_list_page: 'List of news',
	news_management: 'News',
	news_register: 'Create a new',
	news_title: 'Title',
	news_update: 'Edit new',
	news_url: 'Url',
	no: 'No',
	no_records_found: 'No records found.',
	normal_gift_management: 'Normal Gift',
	order: 'Order',
	password: 'Password',
	permission: 'Permission',
	permission_management: 'Permission',
	phone: 'Phone',
	please_choose_date: 'Please choose date',
	please_input: 'Please input',
	please_select_option: 'Please select option',
	post_list_page: 'List of posts',
	post_register: 'Create a post',
	post_update: 'Edit post',
	postal_code: 'Postal code',
	postcode: 'Postcode',
	prefecture: 'Prefecture',
	prefecture_code: 'Prefecture Code',
	prefecture_id: 'Prefecture',
	prefectures: 'Prefectures',
	preview_user: 'Preview user',
	preview_user_management: 'Preview User',
	public_duration: 'Public Duration',
	publish: 'Publish',
	publish_from: 'publish_from',
	publish_to: 'publish_to',
	popup_btn_delete: 'Xóa',
	popup_btn_yes: 'Có',
	popup_btn_no: 'Không',
	rank: 'Rank',
	redeem_experation_date_for_first_lottery: 'Redeem Experation Date for First Lottery',
	redeem_experation_date_for_second_lottery: 'Redeem Experation Date for Second Lottery',
	registered_user: 'Registered User',
	remove: 'Remove',
	remove_all: 'Xóa tất cả',
	reset: 'Reset',
	retry: 'retry',
	review: 'Review',
	review_date: 'Review Date',
	review_deadline: 'Deadline for answer survey',
	review_list_page: 'List of Reviews',
	review_management: 'Review',
	reviews_image: 'Reviews Image',
	reviews_sending: 'Reviews Sending',
	role: 'Role',
	role_list_page: 'List of roles',
	role_register: 'Create a role',
	role_update: 'Edit role',
	roles: 'Role',
	save: 'Save',
	search: 'Search',
	search_by_user_number: 'Search by User Number',
	search_by_period: 'Search by monthly/daily',
	select: 'Select',
	select_category: 'Please select category',
	select_company: 'Please select company',
	select_content: 'Select Content',
	select_file_from_computer: 'Tải ảnh lên',
	select_image: 'Please select image',
	select_prefecture: 'Please select prefecture',
	selected_permission: 'Selected permission',
	sending_date: 'Sending Date',
	sending_message: 'Sending Message',
	select_option_empty: 'Bỏ chọn',
	slug: 'Slug',
	sort: 'Sort',
	start_time: 'Ngày bắt đầu',
	status: 'Status',
	submit_add: 'Submit',
	submit_edit: 'Submit Edit',
	survey_management: 'Survey',
	take_me_home: 'Về trang chủ',
	target_user_rank: 'Target User Rank',
	tel: 'Tel',
	tooltip_edit: 'Chỉnh sửa',
	tooltip_delete: 'Xóa',
	tooltip_reset: 'Đặt lại mật khẩu',
	thumbnail: 'Thumbnail',
	title: 'Title',
	to: 'to',
	total_users: 'Total users',
	type: 'Type',
	update_message_successfully: 'Update successfully',
	updated_at: 'Updated At',
	upload: 'Tải lên',
	upload_message_error: 'Upload image error',
	upload_message_successfully: 'Upload image successfully',
	upload_process: 'Hình tải lên',
	url: 'Homepage Url',
	user_list_page: 'List of users',
	user_register: 'Create an user',
	user_update: 'Edit user',
	username: 'Username',
	view: 'view',
	website: 'Website',
	winner_limitation: 'Winner Limitation',
	winning_notice_message: 'Winning Notice Message',
	winning_notice_sending: 'Winning Notice Sending',
	yes: 'Yes',
	tooltip_active: 'Kích hoạt',
	tooltip_deactive: 'Vô hiệu hóa',
	tooltip_is_legal_representative: 'Chọn làm đại diện pháp luật',
	tooltip_isnt_legal_representative: 'Bỏ chọn làm đại diện pháp luật',
}
