export const PERMISSIONS = {
	ALL: "all",

	VIEW_DASHBOARD: "VIEW_DASHBOARD",

	VIEW_PROPERTIES: "VIEW_PROPERTIES",

	VIEW_MENU_FOLLOW_PROFILE: 'VIEW_MENU_FOLLOW_PROFILE',

	VIEW_ROLE: "VIEW_ROLE",
	ADD_ROLE: "ADD_ROLE",
	EDIT_ROLE: "EDIT_ROLE",
	DELETE_ROLE: "DELETE_ROLE",
	IMPORT_ROLE: "IMPORT_ROLE",
	EXPORT_ROLE: "EXPORT_ROLE",
	ACCEPT_ROLE: "ACCEPT_ROLE",

	VIEW_USER: "VIEW_USER",
	ADD_USER: "ADD_USER",
	EDIT_USER: "EDIT_USER",
	DELETE_USER: "DELETE_USER",
	IMPORT_USER: "IMPORT_USER",
	EXPORT_USER: "EXPORT_USER",
	ACCEPT_USER: "ACCEPT_USER",

	VIEW_CUSTOMER: "VIEW_CUSTOMER",
	ADD_CUSTOMER: "ADD_CUSTOMER",
	EDIT_CUSTOMER: "EDIT_CUSTOMER",
	DELETE_CUSTOMER: "DELETE_CUSTOMER",
	IMPORT_CUSTOMER: "IMPORT_CUSTOMER",
	EXPORT_CUSTOMER: "EXPORT_CUSTOMER",
	ACCEPT_CUSTOMER: "ACCEPT_CUSTOMER",

	VIEW_CATEGORY: "VIEW_CATEGORY",
	ADD_CATEGORY: "ADD_CATEGORY",
	EDIT_CATEGORY: "EDIT_CATEGORY",
	DELETE_CATEGORY: "DELETE_CATEGORY",
	EXPORT_CATEGORY: "EXPORT_CATEGORY",
	ACCEPT_CATEGORY: "ACCEPT_CATEGORY",

	VIEW_PRICE: "VIEW_PRICE",
	ADD_PRICE: "ADD_PRICE",
	EDIT_PRICE: "EDIT_PRICE",
	DELETE_PRICE: "DELETE_PRICE",
	IMPORT_PRICE: "IMPORT_PRICE",
	EXPORT_PRICE: "EXPORT_PRICE",
	ACCEPT_PRICE: "ACCEPT_PRICE",

	VIEW_MAP: "VIEW_MAP",
	ADD_MAP: "ADD_MAP",
	EDIT_MAP: "EDIT_MAP",
	DELETE_MAP: "DELETE_MAP",
	IMPORT_MAP: "IMPORT_MAP",
	EXPORT_MAP: "EXPORT_MAP",
	ACCEPT_MAP: "ACCEPT_MAP",

	VIEW_APPRAISE: "VIEW_APPRAISE",
	ADD_APPRAISE: "ADD_APPRAISE",
	EDIT_APPRAISE: "EDIT_APPRAISE",
	DELETE_APPRAISE: "DELETE_APPRAISE",
	IMPORT_APPRAISE: "IMPORT_APPRAISE",
	EXPORT_APPRAISE: "EXPORT_APPRAISE",
	ACCEPT_APPRAISE: "ACCEPT_APPRAISE",

	VIEW_CERTIFICATE: "VIEW_CERTIFICATE",
	ADD_CERTIFICATE: "ADD_CERTIFICATE",
	EDIT_CERTIFICATE: "EDIT_CERTIFICATE",
	DELETE_CERTIFICATE: "DELETE_CERTIFICATE",
	IMPORT_CERTIFICATE: "IMPORT_CERTIFICATE",
	EXPORT_CERTIFICATE: "EXPORT_CERTIFICATE",
	ACCEPT_CERTIFICATE: "ACCEPT_CERTIFICATE",

	VIEW_CERTIFICATE_ASSET: "VIEW_CERTIFICATE_ASSET",
	ADD_CERTIFICATE_ASSET: "ADD_CERTIFICATE_ASSET",
	EDIT_CERTIFICATE_ASSET: "EDIT_CERTIFICATE_ASSET",
	DELETE_CERTIFICATE_ASSET: "DELETE_CERTIFICATE_ASSET",
	IMPORT_CERTIFICATE_ASSET: "IMPORT_CERTIFICATE_ASSET",
	EXPORT_CERTIFICATE_ASSET: "EXPORT_CERTIFICATE_ASSET",
	ACCEPT_CERTIFICATE_ASSET: "ACCEPT_CERTIFICATE_ASSET",

	VIEW_CERTIFICATE_BRIEF: "VIEW_CERTIFICATE_BRIEF",
	ADD_CERTIFICATE_BRIEF: "ADD_CERTIFICATE_BRIEF",
	EDIT_CERTIFICATE_BRIEF: "EDIT_CERTIFICATE_BRIEF",
	DELETE_CERTIFICATE_BRIEF: "DELETE_CERTIFICATE_BRIEF",
	IMPORT_CERTIFICATE_BRIEF: "IMPORT_CERTIFICATE_BRIEF",
	EXPORT_CERTIFICATE_BRIEF: "EXPORT_CERTIFICATE_BRIEF",
	ACCEPT_CERTIFICATE_BRIEF: "ACCEPT_CERTIFICATE_BRIEF",

	VIEW_PRE_CERTIFICATE: "VIEW_PRE_CERTIFICATE",
	ADD_PRE_CERTIFICATE: "ADD_PRE_CERTIFICATE",
	EDIT_PRE_CERTIFICATE: "EDIT_PRE_CERTIFICATE",
	DELETE_PRE_CERTIFICATE: "DELETE_PRE_CERTIFICATE",
	IMPORT_PRE_CERTIFICATE: "IMPORT_PRE_CERTIFICATE",
	EXPORT_PRE_CERTIFICATE: "EXPORT_PRE_CERTIFICATE",
	ACCEPT_PRE_CERTIFICATE: "ACCEPT_PRE_CERTIFICATE",

	VIEW_ACCOUNTING: "VIEW_ACCOUNTING",
	ADD_ACCOUNTING: "ADD_ACCOUNTING",
	EDIT_ACCOUNTING: "EDIT_ACCOUNTING",
	DELETE_ACCOUNTING: "DELETE_ACCOUNTING",
	IMPORT_ACCOUNTING: "IMPORT_ACCOUNTING",
	EXPORT_ACCOUNTING: "EXPORT_ACCOUNTING",
	ACCEPT_ACCOUNTING: "ACCEPT_ACCOUNTING",

	VIEW_CONFIG_WORKFLOW: "VIEW_CONFIG_WORKFLOW",
	EDIT_CONFIG_WORKFLOW: "EDIT_CONFIG_WORKFLOW",

	VIEW_PRELIMINARY_ASSET: "VIEW_PRELIMINARY_ASSET",
	ADD_PRELIMINARY_ASSET: "ADD_PRELIMINARY_ASSET",
	EDIT_PRELIMINARY_ASSET: "EDIT_PRELIMINARY_ASSET",
	DELETE_PRELIMINARY_ASSET: "DELETE_PRELIMINARY_ASSET",
	IMPORT_PRELIMINARY_ASSET: "IMPORT_PRELIMINARY_ASSET",
	EXPORT_PRELIMINARY_ASSET: "EXPORT_PRELIMINARY_ASSET",
	ACCEPT_PRELIMINARY_ASSET: "ACCEPT_PRELIMINARY_ASSET"
};
