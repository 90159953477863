let screens = [];
let certificate_lookup = false
const loadScreenConfigs = async () => {
    const clientName = process.env.VUE_APP_CLIENT_NAME || 'Default';
    try {
        const config = await import(`@/config/screens/screens${clientName}.js`);
        screens = config.screens || [];
        certificate_lookup = config.certificate_lookup || false;
    } catch (error) {
        console.error('Error loading client config, falling back to default:', error);
    }
};

loadScreenConfigs();

export { screens, certificate_lookup };