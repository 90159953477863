export default {
	'validation.format_url': '正しい形式のURLを指定してください。',
	'validation.post_code': '正しい形式の郵便番号（ハイフンあり）を指定してください。',
	'validation.smaller_than': '最小年齢は最大年齢より下で入力してください。',
	active: '有効',
	add_button: 'ボタンを追加',
	add_content: 'コンテンツページ',
	add_member_barcode: '会員証',
	add_more_content_video: 'セクションを追加',
	add_news: 'お知らせ',
	add_video: '動画コンテンツ',
	address: '住所',
	address_default: '東京都渋谷区東1-26-30 シブヤイーストビル3階/4階',
	admin: '管理者管理',
	admin_create: '管理者登録',
	admin_edit: '管理者編集',
	admin_group_id: '権限名',
	admin_list: '管理者一覧',
	admin_name: '管理者名',
	all_shop: '全店共通',
	android_apikey: 'push通知用 Google APIキー',
	android_open_count: 'Android開封数',
	android_push_count: 'Android配信数',
	app: 'アプリ管理',
	app_footer_category_id: 'リンク先',
	app_title: 'アプリ設定編集',
	app_update_time_extension: '更新',
	available_permission: '権限一覧',
	back: '戻る',
	background_member: '背景画像',
	banner: 'バナー管理',
	banner_create_page: 'バナー登録',
	banner_image: 'バナー画像',
	banner_list_page: 'バナー一覧',
	banner_list_page_title: 'バナー登録',
	banner_management: 'バナー管理',
	banner_register: 'バナー登録',
	banner_title: 'バナータイトル',
	banner_title_table: 'タイトル',
	banner_update_page: 'バナー編集',
	banner_url: '外部URL',
	beacon_name: 'ビーコン名',
	beacon_type: 'ビーコンタイプ',
	beacon_values: 'ビーコン名',
	bg_member_card: '会員証背景画像',
	birthday: '生年月日',
	btn_copy: 'コピー',
	btn_create: '新規作成する',
	btn_delete: '削除',
	btn_edit: '編集',
	btn_reflect: '反映する',
	btn_reset: ' リセット',
	btn_save: ' 登録する',
	btn_save_preview: '下書きを保存する',
	btn_save_production: '公開する',
	btn_search: ' 検索',
	btn_update: ' 編集を確定',
	button_color: 'ボタン文字',
	button_inactive_color: '非活性ボタン文字',
	cancel: 'キャンセル',
	change_count_now: '対象者数をすぐに変更する',
	chart_display_type: '月別/日別',
	chart_title: 'アプリ会員数推移',
	choose_file: 'ファイルを選択…',
	choose_type_video: '動画のタイプを選択',
	city: '市区町村',
	color_bg_footer: 'アイコンの背景カラー',
	color_hover: '選択中',
	color_not_hover: '未選択中',
	color_text_footer: 'アイコンのテキストカラー',
	company: '企業管理',
	company_code: '企業コード',
	company_create_page: '企業登録',
	company_edit_page: '企業編集',
	company_id: '企業名',
	company_list_page: '企業一覧',
	company_name: '企業名',
	confirm_modal: '設定',
	confirm_preview: '公開モードとして表示されています。アプリを下書きとして保存しますか？',
	confirm_production: '下書きモードとして表示されています。アプリを公開しますか？',
	content: 'コンテンツ管理',
	content_column: '表示タイプ',
	content_create: 'コンテンツ登録',
	content_edit: 'コンテンツ編集',
	content_limit: '表示件数',
	content_list: 'コンテンツ一覧',
	content_member_barcode: 'マイページ設定で会員証画像と背景画像が設定できます。',
	content_public_time: '公開期間',
	content_text: '内容',
	content_text_type: '内部コンテンツ',
	content_title: 'タイトル',
	content_type: 'リンク',
	content_url_type: '外部URL',
	contents: '表示コンテンツ',
	coupon: 'クーポン',
	coupon_code: 'クーポンコード',
	coupon_create: 'クーポン登録',
	coupon_edit: 'クーポン編集',
	coupon_id: 'コンプリートクーポン',
	coupon_management: 'クーポン管理',
	coupon_name: 'クーポン名',
	coupon_password: 'クーポン消込パスワード',
	coupon_publish_time: '公開期間',
	coupon_type: 'クーポン種類',
	create_btn: '登録する',
	create_message_successfully: ' 登録が完了しました。',
	create_template: '新規追加',
	created_at: '登録日時',
	daily: '日別',
	dashboard: 'ホーム',
	dashboard_left_menu: 'ダッシュボード',
	date_expired: '有効期限',
	date_expired_placeholder: '有効期限入力',
	delete_content: '本当に削除してもよろしいでしょうか？',
	delete_data_app_is_editing: '他のテンプレートを選択する場合、データが削除されます。<br>よろしいでしょうか。',
	delete_message_error: '削除に失敗しました。',
	delete_message_successfully: '削除が完了しました。',
	delete_push: '本当に削除しますか',
	description_role: '概要',
	detection_device: '検知デバイス管理',
	detection_device_create: '検知デバイス登録',
	detection_device_edit: '検知デバイス編集',
	detection_device_list: '検知デバイス一覧',
	dimenssion_logo: '推奨サイズ H:100px W:400px',
	display_content: '表示コンテンツ（複数選択）',
	display_name_role: '権限名',
	display_order: '表示順(昇順)',
	display_position: '表示位置',
	district: '市区町村',
	drag_file: 'ファイルをドラッグアンドドロップしてください',
	duration: '公開期間',
	each_shop: '店舗ごと',
	edit_eye_catch: 'アイキャッチ編集',
	editor_counter_note: '※HTMLエディタではHTMLタグを含んだ文字数が表示されます。',
	email: 'メールアドレス',
	empty_company: 'エディターを利用する前に、企業を選択してください。',
	enable_temporary_member: '仮会員を有効にする',
	end_time: '終了日時',
	error: 'Error',
	error_400_content: 'タイプミス等、リクエストにエラーがあります。',
	error_401_content: '認証に失敗しました。',
	error_403_content: '申し訳ございません。 アクセスが拒否されました。',
	error_404_content: '該当アドレスのページはありません、またはそのサーバーが落ちています。',
	error_409_content: '管理者管理に使用されている権限です。',
	error_429_content: 'リクエストが多すぎます',
	error_500_content: 'スクリプトなどでエラーが出ました。',
	error_503_content: 'そのアドレスは事情によりアクセスできません。',
	error_happened_please_try_again: 'Upload process got problem.',
	error_title: 'エラーページが見つかりました。',
	fax: 'FAX（ハイフンあり）',
	footer_button: 'ボタン設定',
	footer_name: 'ボタン名',
	footer_type: 'リンク',
	freedial: 'フリーダイヤル（ハイフンあり）',
	furigana: 'フリガナ',
	gender: '性別',
	gender_female: '女性',
	gender_male: '男性',
	gender_other: 'その他',
	general_background_color: '背景色',
	general_text_color: '文字色',
	google_key: 'Google APIキー',
	html_editor: 'html_editor',
	image_coupon: 'クーポン画像',
	image_icon_footer_default: '未選択中のアイコン画像',
	image_icon_footer_upload: '選択中のアイコン画像',
	inactive: '無効',
	ios_credential: 'push通知用 Apple証明書',
	ios_open_count: 'iOS開封数',
	ios_privatekey: 'push通知用 Appleプライベートキー',
	ios_push_count: 'iOS配信数',
	is_preview: '下書き',
	is_production: '公開済み',
	label_title: 'タイトル入力',
	lat: '緯度',
	limit_dimension_footer_button: '上限:300kB',
	limit_dimension_logo: 'サイズはwidth:400px, height:100px以内に収めてください。',
	limit_eye_catch: '「画像」上限:2MB',
	limit_eye_catch_video: '「動画」上限：10MB',
	limit_footer_button: 'アイコン数：５個まで',
	limit_size_eye_catch: '推奨サイズ width:700px',
	limit_size_footer_button: '推奨サイズ width:700px',
	link_detail: '詳細はこちら',
	link_type: '内部ページ',
	load_more: 'もっと見る',
	login: 'ログイン',
	login_id: 'ログインID',
	login_password: 'ログインパスワード',
	login_password_confirmation: 'ログインパスワード確認',
	login_password_placeholder: 'パスワード入力',
	login_username_placeholder: 'ユーザー名',
	logout: 'ログアウト',
	lon: '経度',
	mail: 'メールアドレス',
	major_no: 'MAJOR NO',
	major_no_jp: 'メジャー NO',
	max_age: '最大年齢',
	member_card: '会員証画像',
	message_confirm_production: 'production',
	message_confirm_review: 'review',
	message_error: 'エラー',
	message_success: '完了',
	min_age: '最小年齢',
	minor_no: 'MINOR NO',
	minor_no_jp: 'マイナー NO',
	mode_app_setting: 'モード',
	monthly: '月別',
	name_app_setting: 'テンプレート名',
	name_role: '名前',
	news_category: 'お知らせカテゴリー',
	news_create: 'お知らせ登録',
	news_display_count: '表示件数',
	news_edit: 'お知らせ編集',
	news_management: 'お知らせ管理',
	news_management_page_title: 'お知らせ登録',
	news_text: 'お知らせ内容',
	news_url: '外部URL',
	no: 'いいえ',
	not_found_banner: 'バナーはありません。',
	not_found_eye_catch: 'アイキャッチはありません。',
	not_template: 'テンプレート未設定',
	note_company_code: '※企業コードは一度登録すると変更できません。',
	ok: 'ok',
	phone: '電話番号（ハイフンあり）',
	placeholder_areas: '選択してください',
	placeholder_birthday: 'yyyy-mm-dd',
	placeholder_company_id: '選択してください',
	placeholder_postcode: '150-0021',
	placeholder_prefecture: '選択してください',
	placeholder_tel: '090-1234-5678',
	placeholder_youtube: 'https://youtube.com/',
	please_select_option: '選択してください',
	popover_select_area: 'エリアを変更すると都道府県、市区町村、住所、経度、緯度は全てクリアされます。よろしいですか？',
	popup_btn_delete: '削除する',
	popup_btn_delete_data_edit: 'はい',
	post_code: '郵便番号（ハイフンあり）',
	prefecture: '都道府県',
	publish_coupon_type: 'クーポン発行方法',
	push: 'Push配信管理',
	push_category: 'Push配信カテゴリー',
	push_count_content: 'Push通知メッセージの対象者数',
	push_datetime: '予約配信日時',
	push_list_page: 'Push配信一覧',
	push_management: 'Push配信管理',
	push_message: 'Push通知メッセージ',
	push_register: 'Push配信登録',
	push_schedule_datetime: '予定配信日時',
	push_update_page: 'Push配信編集',
	push_url: '通知URL',
	qrcode_values: 'QR Code',
	refresh: 'リロード',
	remove_all: 'Remove All',
	required: '必須',
	reset: 'リセット',
	result_display: '表示件数',
	role: '権限管理',
	role_admin_name: '権限名',
	role_create: '権限登録',
	role_edit: '権限編集',
	role_list: '権限一覧',
	role_list_page_title: '権限登録',
	search: '検索',
	search_btn: '検索する',
	search_category: '選択してください',
	search_latlng: '座標で検索する',
	search_map: '住所で検索する',
	section_content: 'コンテンツページ',
	section_member_card: '会員証',
	section_movie: '動画コンテンツ',
	section_news: 'お知らせ',
	section_title_my_page: '会員バーコード',
	select_file_from_computer: 'アップロード',
	select_option_active: '有効',
	select_option_empty: '選択してください',
	select_option_inactive: '無効',
	select_preview: '下書きを開く',
	select_production: '公開済みを開く',
	selected_permission: '選択した権限',
	service_data: 'SERVICE DATA',
	service_terms: '利用規約',
	setting_banner: 'バナー設定',
	setting_block_content: 'セクション設定',
	setting_display_content: 'コンテンツ設定',
	setting_display_contents: '表示コンテンツ',
	setting_display_member: '会員証設定',
	setting_display_movie: '動画設定',
	setting_display_news: 'お知らせ設定',
	setting_eye_catch: 'アイキャッチ設定',
	setting_my_page: 'マイページ設定',
	shop: '店舗管理',
	shop_address: '住所',
	shop_area: 'エリア',
	shop_code: '店舗コード',
	shop_content: '備考',
	shop_create: '店舗登録',
	shop_district: '市区町村',
	shop_edit: '店舗編集',
	shop_fax: 'FAX',
	shop_free_dial: 'フリーダイヤル',
	shop_list: '店舗一覧',
	shop_mail: 'メールアドレス',
	shop_name: '店舗名',
	shop_prefecture: '都道府県',
	shop_tel: '電話番号',
	size_upload_logo: '上限:300kB',
	slick_seconds: 'スライド秒数',
	stamp: 'スタンプ',
	stamp_active_day: '有効期限',
	stamp_background: 'スタンプ台紙',
	stamp_card: '来店スタンプ',
	stamp_check_stamp_frame_exist: 'スタンプリンクを設定する前にスタンプ枠を入力してください。',
	stamp_completed_coupon: 'コンプリートクーポン',
	stamp_create: 'スタンプ登録',
	stamp_daily_limit: '1日制限回数',
	stamp_edit: 'スタンプ編集',
	stamp_frame: 'スタンプ枠',
	stamp_image: 'スタンプ画像',
	stamp_list: 'スタンプ一覧',
	stamp_management: 'スタンプ管理',
	stamp_name: 'スタンプカード名',
	stamp_publish_stamp_type: 'スタンプ押印方法',
	stamp_publish_time: '公開期間',
	stamp_rally: 'スタンプラリー',
	stamp_text: 'スタンプ内容',
	stamp_type: 'スタンプ種類',
	stamp_using_scope: 'スタンプ用途',
	start_time: '開始日時',
	status: 'ステータス',
	tab_entire: '全体',
	tab_eye_catch: 'アイキャッチ',
	tab_footer_button: 'フッターボタン',
	tab_layout: 'レイアウト',
	tab_privacy_term: '利用規約',
	tab_template: 'テンプレート',
	take_me_home: 'ダッシュボードへ戻る',
	tel: '電話番号',
	template_name: 'テンプレート名',
	text_active: '有効',
	text_create_admin: '管理者登録',
	text_create_content: 'コンテンツ登録',
	text_create_shop: '店舗登録',
	text_create_user: '会員登録',
	text_edit_admin: '管理者編集',
	text_edit_content: 'コンテンツ編集',
	text_edit_shop: '店舗編集',
	text_edit_user: '会員編集',
	text_preview: '下書き参照',
	text_search: ' 検索',
	theme_color: 'テーマカラー',
	thumbnail: '画像',
	thumbnail_coupon: 'クーポンサムネイル',
	time_show: '表示期間',
	title_active: 'タイトルをトップに表示',
	title_background: 'タイトル背景',
	title_delete_data_edit: '確認',
	title_device: 'iphone6/7/8サイズ',
	title_list_banner: 'バナー',
	title_logo: 'ロゴ',
	title_modal_banner: 'バナー設定',
	title_modal_eye_catch: 'アイキャッチ設定',
	title_preview_app: 'テンプレートプレビュー',
	title_template_thumbnail: 'テンプレートサムネイル',
	title_text_color: 'タイトル文字',
	tooltip_copy: '複製',
	tooltip_delete: '削除',
	tooltip_edit: '編集',
	total_member: 'アプリ会員総数',
	type_content: 'コンテンツタイプ',
	type_eye_catch: 'アイキャッチのタイプを選択',
	type_image_title: '画像表示',
	type_text_title0: 'リストタイプ',
	type_text_title1: 'ブログタイプ',
	type_text_title2: 'タイルタイプ２列',
	type_text_title3: 'タイルタイプ３列',
	type_text_title: '文字入力',
	type_title: 'タイトルのタイプを選択',
	type_url: 'リンクタイプ',
	type_video: '動画タイプ',
	update_message_successfully: '更新が完了しました。',
	updated_at: '編集日時',
	upload: 'アップロード',
	upload_banner: 'アイキャッチ',
	upload_image: '画像ファイル選択',
	upload_label_color: 'キーカラー<br>（強調ラベル）',
	upload_logo_title: 'ロゴ画像',
	upload_message_error: '画像アップロードに失敗しました。',
	upload_message_successfully: '画像アップロードが完了しました。',
	upload_policy: 'プライバシーポリシー',
	upload_primary_color: 'メインカラー',
	upload_process: 'アップロード',
	upload_secondary_color: 'サブカラー',
	upload_term: '利用規約',
	upper_limit_member: '上限:1MB',
	url: 'ホームページアドレス',
	url_type: '外部URL',
	used_coupon_log_management: 'クーポン使用履歴',
	used_stamp_log_management: 'スタンプ使用履歴',
	user: '会員管理',
	used_coupon_log_user_id: 'ユーザーID',
	used_coupon_log_date_use: '利用日付',
	used_coupon_log_user_name: 'ユーザー名',
	used_coupon_log_coupon_name: 'クーポン名',
	used_coupon_log_store: '店舗',
	used_stamp_log_user_id: 'ユーザーID',
	used_stamp_log_date_use: '利用日付',
	used_stamp_log_user_name: 'ユーザー名',
	used_stamp_log_stamp_name: 'スタンプカード名',
	used_stamp_log_store: '店舗',
	btn_export: 'CSVエクスポート',
	user_create: '会員登録',
	user_edit: '会員編集',
	user_list: '会員一覧',
	user_list_page_title: '会員登録',
	user_name: 'ニックネーム',
	user_number: '会員番号',
	uuid: 'UUID',
	validate_is_image: '画像をアップロードしてください。',
	validate_multi_upload: '画像を1枚だけ選んでください。',
	validate_size_upload: 'アップロードできるファイルは{msg}以内です',
	video: '動画',
	video_title: 'タイトル',
	yes: 'はい',
	youtube: 'YouTube',
	youtube_url: 'YouTube URL',

	// Permission
	VIEW_ROLE: '権限表示',
	CREATE_ROLE: '権限作成',
	UPDATE_ROLE: '権限更新',
	DELETE_ROLE: '権限削除',

	VIEW_ADMIN: '管理者表示',
	CREATE_ADMIN: '管理者作成',
	UPDATE_ADMIN: '管理者更新',
	DELETE_ADMIN: '管理者削除',

	VIEW_USER: '会員表示',
	CREATE_USER: '会員作成',
	UPDATE_USER: '会員更新',
	DELETE_USER: '会員削除',

	VIEW_COMPANY: '企業表示',
	CREATE_COMPANY: '企業作成',
	UPDATE_COMPANY: '企業更新',
	DELETE_COMPANY: '企業削除',

	VIEW_BANNER: 'バナー表示',
	CREATE_BANNER: 'バナー作成',
	UPDATE_BANNER: 'バナー更新',
	DELETE_BANNER: 'バナー削除',

	VIEW_SHOP: '店舗表示',
	CREATE_SHOP: '店舗作成',
	UPDATE_SHOP: '店舗更新',
	DELETE_SHOP: '店舗削除',

	VIEW_CONTENT: 'コンテンツ表示',
	CREATE_CONTENT: 'コンテンツ作成',
	UPDATE_CONTENT: 'コンテンツ更新',
	DELETE_CONTENT: 'コンテンツ削除',

	VIEW_NEWS: 'お知らせ表示',
	CREATE_NEWS: 'お知らせ作成',
	UPDATE_NEWS: 'お知らせ更新',
	DELETE_NEWS: 'お知らせ削除',

	VIEW_APP_SETTING: 'アプリ設定表示',
	CREATE_APP_SETTING: 'アプリ設定作成',
	UPDATE_APP_SETTING: 'アプリ設定更新',
	DELETE_APP_SETTING: 'アプリ設定削除',

	VIEW_PUSH: 'Push設定表示',
	CREATE_PUSH: 'Push設定作成',
	UPDATE_PUSH: 'Push設定更新',
	DELETE_PUSH: 'Push設定削除',

	VIEW_COUPON: 'クーポン表示',
	CREATE_COUPON: 'クーポン作成',
	UPDATE_COUPON: 'クーポン更新',
	DELETE_COUPON: 'クーポン削除',

	VIEW_DETECTION_DEVICE: '検知デバイス表示',
	CREATE_DETECTION_DEVICE: '検知デバイス作成',
	UPDATE_DETECTION_DEVICE: '検知デバイス更新',
	DELETE_DETECTION_DEVICE: '検知デバイス削除',

	VIEW_STAMP: 'スタンプ表示',
	CREATE_STAMP: 'スタンプ作成',
	UPDATE_STAMP: 'スタンプ更新',
	DELETE_STAMP: 'スタンプ削除',

	VIEW_USED_COUPON_LOG: 'クーポン使用ログ表示',
	DELETE_USED_COUPON_LOG: 'クーポン使用ログ削除',

	VIEW_USED_STAMP_LOG: 'スタンプ使用ログ表示',
	DELETE_USED_STAMP_LOG: 'スタンプ使用ログ削除',

	VIEW_DASHBOARD: 'ダッシュボード表示'
}
