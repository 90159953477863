let round_number = 'NORMAL';
let standard = []
const loadAppraisalMethods = async () => {
    const clientName = process.env.VUE_APP_CLIENT_NAME || 'Default';
    try {
        const config = await import(`@/config/appraisalMethods/appraisalMethods${clientName}.js`);
        round_number = config.round_number || 'NORMAL';
        standard = config.standard || ['bddc', 'tgtdcg', 'mgcd'];
    } catch (error) {
        console.error('Error loading client config, falling back to default:', error);
    }
};

loadAppraisalMethods();

export { round_number, standard };