let image_background = ''
let image_header_kqsb = ''
const loadConfigImages = async () => {
    const clientName = process.env.VUE_APP_CLIENT_NAME || 'Default';
    try {
        const config = await import(`@/config/images/images${clientName}.js`);
        image_background = config.image_background || require('@/assets/images/hinh_nen_login_donava.jpg');
        image_header_kqsb = config.image_header_kqsb || require('@/assets/images/header_kqsb_donava.png');
    } catch (error) {
        console.error('Error loading client config, falling back to default:', error);
    }
};

loadConfigImages();

export { image_background, image_header_kqsb };