let navigationsMobile = [];

const loadNavigations = async () => {
	const clientName = process.env.VUE_APP_CLIENT_NAME || 'Default';
	try {
		const config = await import(`@/config/navigationsMobile/navigationsMobile${clientName}.js`);
		navigationsMobile = config.navigationsMobile || [];
	} catch (error) {
		console.error('Error loading client config, falling back to default:', error);
	}
};

loadNavigations();

export { navigationsMobile };