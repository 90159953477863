import Resource from "@/components/Resource";
import { AuthGuard, LoginGuard, ResolveGuard } from "@/router/guards";

import { PERMISSIONS } from "@/enum/permissions.enum";

export function page(path) {
	return () => import(/* webpackChunkName: "[request]" */ `@/pages/${path}`);
}

export const routes = [
	// Menu mobile
	{
		path: "/menu",
		component: Resource,
		children: [
			{
				path: "",
				name: "menu",
				component: page("HomeMobile.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					permissions: [PERMISSIONS.VIEW_USER],
					title: "Menu danh mục",
					gtm: "Menu danh mục"
				}
			}
		]
	},
	// map
	{
		path: "/map",
		name: "map.index",
		component: page("map/Index.vue"),
		beforeEnter: ResolveGuard([AuthGuard]),
		meta: {
			title: "Bản đồ giá",
			permissions: [PERMISSIONS.VIEW_MAP],
			breadcrumbs: [{ title: "Bản đồ giá", name: "map.index" }],
			gtm: "Trang bản đồ giá"
		}
	},
	// Login
	{
		path: "/login",
		name: "login",
		component: page("Login.vue"),
		meta: { layout: "auth", gtm: "Trang đăng nhập" },
		beforeEnter: ResolveGuard([LoginGuard])
	},
	{
		path: "/ping",
		name: "ping",
		component: page("Ping.vue"),
		meta: { layout: "auth", gtm: "Trang ping" }
	},
	{
		path: "/verify",
		name: "verify",
		component: page("Verify.vue"),
		meta: { layout: "auth", gtm: "Trang kiểm tra thông tin" },
		beforeEnter: ResolveGuard([LoginGuard])
	},

	// Profile
	{
		path: "/profile",
		component: Resource,
		children: [
			{
				path: "",
				name: "profile.index",
				component: page("profile/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thông tin cá nhân",
					permissions: [PERMISSIONS.VIEW_USER],
					breadcrumbs: [{ title: "Thông tin cá nhân", name: "profile.index" }],
					gtm: "Trang thông tin cá nhân"
				}
			},
			{
				path: "/profile/edit",
				name: "profile.edit",
				component: page("profile/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa thông tin cá nhân",
					permissions: [PERMISSIONS.EDIT_USER],
					breadcrumbs: [{ title: "Thông tin cá nhân", name: "profile.index" }, { title: "Chỉnh sửa thông tin cá nhân", name: "profile.edit" }],
					gtm: "Trang chỉnh sửa thông tin cá nhân"
				}
			},
			{
				path: "/profile/change-password",
				name: "profile.password",
				component: page("profile/ChangePassword.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thay đổi mật khẩu",
					permissions: [PERMISSIONS.EDIT_USER],
					breadcrumbs: [{ title: "Thông tin cá nhân", name: "profile.index" }, { title: "Thay đổi mật khẩu", name: "profile.password" }],
					gtm: "Trang thay đổi mật khẩu"
				}
			}
		]
	},
	// Configuration
	{
		path: "/configuration",
		component: Resource,
		children: [
			{
				path: "",
				name: "configuration.index",
				component: page("configuration/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Cấu hình",
					permissions: [PERMISSIONS.VIEW_PROPERTIES],
					breadcrumbs: [{ title: "Cấu hình", name: "configuration.index" }],
					gtm: "Trang cấu hình"
				}
			}
		]
	},
	{
		path: "/configuration/update-certificate-brief-sub-status",
		component: Resource,
		children: [
			{
				path: "",
				name: "UpdateCertificateBriefSubStatus.index",
				component: page("configuration/UpdateCertificateBriefSubStatus.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Cập nhật sub-status",
					permissions: [PERMISSIONS.VIEW_PROPERTIES],
					breadcrumbs: [
						{
							title: "Cập nhật sub-status",
							name: "UpdateCertificateBriefSubStatus.index"
						}
					],
					gtm: "Trang cập nhật sub-status"
				}
			}
		]
	},

	{
		path: "/configuration/dashboard-config",
		component: Resource,
		children: [
			{
				path: "",
				name: "DashBoardConfig.index",
				component: page("configuration/DashBoardConfig.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Cấu hình Dashboard",
					permissions: [PERMISSIONS.VIEW_PROPERTIES],
					breadcrumbs: [
						{
							title: "Cấu hình Dashboard",
							name: "DashBoardConfig.index"
						}
					],
					gtm: "Trang cấu hình Dashboard"
				}
			}
		]
	},
	{
		path: "/configuration/principle-config",
		component: Resource,
		children: [
			{
				path: "",
				name: "PrincipleConfig.index",
				component: page("configuration/PrincipleConfig.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Cấu hình quy trình",
					permissions: [PERMISSIONS.VIEW_PROPERTIES],
					breadcrumbs: [
						{
							title: "Cấu hình quy trình",
							name: "PrincipleConfig.index"
						}
					],
					gtm: "Trang cấu hình quy trình"
				}
			}
		]
	},
	// {
	// 	path: '/configuration',
	// 	component: Resource,
	// 	children: [{
	// 		path: '',
	// 		name: 'configuration.index',
	// 		component: page('configuration/Index.vue'),
	// 		beforeEnter: ResolveGuard([AuthGuard]),
	// 		meta: {
	// 			title: 'Cấu hình',
	// 			permissions: [PERMISSIONS.VIEW_PROPERTIES],
	// 			breadcrumbs: [
	// 				{ title: 'Cấu hình', name: 'configuration.index' }
	// 			],
	// 			gtm: 'Trang cấu hình'
	// 		}
	// 	}]
	// },
	{
		path: "/log-estimate",
		component: Resource,
		children: [
			{
				path: "",
				name: "log.index",
				component: page("log_estimate/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Lịch sử ước tính",
					permissions: [PERMISSIONS.VIEW_PRICE],
					breadcrumbs: [{ title: "Lịch sử ước tính", name: "log.index" }],
					gtm: "Trang lịch sử ước tính"
				}
			},
			{
				path: "detail",
				name: "log.detail",
				component: page("log_estimate/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Lịch sử ước tính",
					permissions: [PERMISSIONS.VIEW_PRICE],
					breadcrumbs: [
						{ title: "Lịch sử ước tính", name: "log.index" },
						{ title: "Chi tiết ước tính", name: "log.detail" }
					],
					gtm: "Trang chi tiết lịch sử ước tính"
				}
			}
		]
	},
	{
		path: "/log-all",
		component: Resource,
		children: [
			{
				path: "",
				name: "logAll.index",
				component: page("log_estimate_all/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Lịch sử ước tính",
					permissions: [PERMISSIONS.VIEW_PRICE],
					breadcrumbs: [{ title: "Lịch sử ước tính", name: "logAll.index" }],
					gtm: "Trang tất cả lịch sử ước tính"
				}
			}
		]
	},
	// import
	{
		path: "/import_street",
		component: Resource,
		children: [
			{
				path: "",
				name: "import_street.index",
				component: page("import_street/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thêm dữ liệu",
					permissions: [PERMISSIONS.VIEW_PROPERTIES],
					breadcrumbs: [{ title: "Thêm dữ liệu", name: "import_street.index" }],
					gtm: "Trang import dữ liệu"
				}
			}
		]
	},
	// Home
	{
		path: "/",
		component: Resource,
		children: [
			{
				path: "",
				name: "home",
				// component: page("map/Index.vue"),
				component: page("HomeMobile.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				// meta: {
				// 	title: "Bản đồ",
				// 	permissions: [PERMISSIONS.VIEW_MAP],
				// 	breadcrumbs: [
				// 		//   { title: 'Bất động sản' }
				// 	],
				// 	gtm: "Trang bản đồ giá"
				// }
				meta: {
					permissions: [PERMISSIONS.VIEW_USER],
					title: "Menu danh mục",
					gtm: "Menu danh mục"
				}
			}
		]
	},
	// Map địa chính
	{
		path: "/map_dia_chinh",
		component: Resource,
		children: [
			{
				path: "",
				name: "map_dia_chinh",
				component: page("map/MapDiaChinh.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tra cứu quy hoạch",
					permissions: [PERMISSIONS.VIEW_MAP],
					breadcrumbs: [
						{ title: 'Tra cứu quy hoạch' }
					],
					gtm: "Trang bản đồ địa chính"
				}
			}
		]
	},
	// warehouse
	{
		path: "/property",
		component: Resource,
		children: [
			{
				path: "",
				name: "warehouse.index",
				component: page("warehouse/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tài sản so sánh",
					permissions: [PERMISSIONS.VIEW_PRICE],
					breadcrumbs: [{ title: "Tài sản so sánh", name: "warehouse.index" }],
					gtm: "Trang tài sản so sánh"
				}
			},
			{
				path: "/property/create",
				name: "warehouse.create",
				component: page("warehouse/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới tài sản so sánh",
					permissions: [PERMISSIONS.ADD_PRICE],
					breadcrumbs: [
						{ title: "Tài sản so sánh", name: "warehouse.index" },
						{
							title: "Tạo mới tài sản so sánh",
							name: "warehouse.create"
						}
					],
					gtm: "Trang tạo mới Tài sản so sánh"
				}
			},
			{
				path: "/property/detail",
				name: "warehouse.detail",
				component: page("warehouse/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết tài sản so sánh",
					permissions: [PERMISSIONS.VIEW_PRICE],
					breadcrumbs: [
						{ title: "Tài sản so sánh", name: "warehouse.index" },
						{
							title: "Chi tiết tài sản so sánh",
							name: "warehouse.detail"
						}
					],
					gtm: "Trang chi tiết Tài sản so sánh"
				}
			},
			{
				path: "/property/edit",
				name: "warehouse.edit",
				component: page("warehouse/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa tài sản so sánh",
					permissions: [PERMISSIONS.EDIT_PRICE],
					breadcrumbs: [
						{ title: "Tài sản so sánh", name: "warehouse.index" },
						{
							title: "Chỉnh sửa tài sản so sánh",
							name: "warehouse.edit"
						}
					],
					gtm: "Trang chỉnh sửa TSSS"
				}
			},
			{
				path: "/property/export",
				name: "warehouse.export",
				component: page("warehouse/Export.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Xuất bảng tổng hợp thông tin",
					permissions: [PERMISSIONS.EDIT_PRICE],
					breadcrumbs: [
						{ title: "Tài sản so sánh", name: "warehouse.index" },
						{
							title: "Xuất bảng tổng hợp thông tin",
							name: "warehouse.export"
						}
					],
					gtm: "Trang xuất Tài sản so sánh"
				}
			}
		]
	},
	// menu theo dõi hồ sơ
	// {
	// 	path: "/menu_certification",
	// 	name: "menu_certification.index",
	// 	component: page("menu_certification/Index.vue"),
	// 	beforeEnter: ResolveGuard([AuthGuard]),
	// 	meta: {
	// 		permissions: [PERMISSIONS.VIEW_MENU_FOLLOW_PROFILE],
	// 		gtm: "Trang theo dõi hồ sơ"
	// 	}
	// },
	{
		path: "/menu_certification",
		component: Resource,
		children: [
			{
				path: "/menu_certification/pre_certificate",
				name: "menu_pre_certification.index",
				component: page("menu_certification/Index2.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tài khoản khách",
					permissions: [PERMISSIONS.VIEW_MENU_FOLLOW_PROFILE],
					breadcrumbs: [
						{
							title: "Tài khoản khách",
							name: "menu_pre_certification.index"
						},
						{
							title: "Yêu cầu sơ bộ",
							name: "menu_pre_certification.index"
						}
					],
					gtm: "Yêu cầu sơ bộ"
				}
			},
			{
				path: "/menu_certification/certificate",
				name: "menu_certification.index",
				component: page("menu_certification/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tài khoản khách",
					permissions: [PERMISSIONS.VIEW_MENU_FOLLOW_PROFILE],
					breadcrumbs: [
						{
							title: "Tài khoản khách",
							name: "menu_certification.index"
						},
						{ title: "Hồ sơ thẩm định", name: "menu_certification.index" }
					],
					gtm: "Hồ sơ thẩm định"
				}
			},

		]
	},
	// report
	// {
	// 	path: '/report/certification_brief',
	// 	component: Resource,
	// 	children: [{
	// 		path: '',
	// 		name: 'report_certification_brief.index',
	// 		component: page('report/CertificationBrief/Index.vue'),
	// 		beforeEnter: ResolveGuard([AuthGuard]),
	// 		meta: {
	// 			title: 'Báo cáo',
	// 			permissions: [PERMISSIONS.VIEW_DASHBOARD],
	// 			breadcrumbs: [
	// 				{ title: 'Báo cáo', name: 'report_certification_brief.index' }
	// 			]
	// 		}
	// 	}
	// {
	//     path: '/property/create',
	//     name: 'warehouse.create',
	//     component: page('warehouse/Create.vue'),
	//     beforeEnter: ResolveGuard([AuthGuard]),
	//     meta: {
	//         title: 'Tạo mới tài sản so sánh',
	//         permissions: [PERMISSIONS.ADD_PRICE],
	//         breadcrumbs: [
	//             { title: 'Kho giá', name: 'warehouse.index' },
	//             { title: 'Tạo mới tài sản so sánh', name: 'warehouse.create' }
	//         ]
	//     }
	// },
	// {
	//     path: '/property/detail',
	//     name: 'warehouse.detail',
	//     component: page('warehouse/Detail.vue'),
	//     beforeEnter: ResolveGuard([AuthGuard]),
	//     meta: {
	//         title: 'Chi tiết tài sản so sánh',
	//         permissions: [PERMISSIONS.VIEW_PRICE],
	//         breadcrumbs: [
	//             { title: 'Kho giá', name: 'warehouse.index' },
	//             { title: 'Chi tiết tài sản so sánh', name: 'warehouse.detail' }
	//         ]
	//     }
	// },
	// {
	//     path: '/property/edit',
	//     name: 'warehouse.edit',
	//     component: page('warehouse/Edit.vue'),
	//     beforeEnter: ResolveGuard([AuthGuard]),
	//     meta: {
	//         title: 'Chỉnh sửa tài sản so sánh',
	//         permissions: [PERMISSIONS.EDIT_PRICE],
	//         breadcrumbs: [
	//             { title: 'Kho giá', name: 'warehouse.index' },
	//             { title: 'Chỉnh sửa tài sản so sánh', name: 'warehouse.edit' }
	//         ]
	//     }
	// }
	// 	]
	// },

	// customer
	{
		path: "/customer",
		component: Resource,
		children: [
			{
				path: "",
				name: "customer.index",
				component: page("customer/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Đối tác",
					permissions: [PERMISSIONS.VIEW_CUSTOMER],
					breadcrumbs: [{ title: "Đối tác", name: "customer.index" }],
					gtm: "Trang Đối tác"
				}
			},
			{
				path: "/customer/group",
				name: "customer.index2",
				component: page("customer/Index2.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Nhóm đối tác",
					permissions: [PERMISSIONS.VIEW_CUSTOMER],
					breadcrumbs: [{ title: "Nhóm đối tác", name: "customer.index2" }],
					gtm: "Trang nhóm đối tác"
				}
			},
			{
				path: "/customer/create",
				name: "customer.create",
				component: page("customer/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới Đối tác",
					permissions: [PERMISSIONS.ADD_CUSTOMER],
					breadcrumbs: [
						{ title: "Đối tác", name: "customer.index" },
						{ title: "Tạo mới Đối tác", name: "customer.create" }
					],
					gtm: "Trang tạo mới Đối tác"
				}
			},
			{
				path: "/customer/detail",
				name: "customer.detail",
				component: page("customer/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết Đối tác",
					permissions: [PERMISSIONS.VIEW_CUSTOMER],
					breadcrumbs: [
						{ title: "Đối tác", name: "customer.index" },
						{ title: "Chi tiết Đối tác", name: "customer.detail" }
					],
					gtm: "Trang chi tiết Đối tác"
				}
			},
			{
				path: "/customer/edit",
				name: "customer.edit",
				component: page("customer/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa Đối tác",
					permissions: [PERMISSIONS.EDIT_CUSTOMER],
					breadcrumbs: [
						{ title: "Đối tác", name: "customer.index" },
						{ title: "Chỉnh sửa Đối tác", name: "customer.edit" }
					],
					gtm: "Trang chỉnh sửa Đối tác"
				}
			},
			// first
			{
				path: "customer-group-first",
				name: "customer_group_first.index",
				component: page("customer/customer_group_first/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Phân cấp",
					permissions: [PERMISSIONS.VIEW_CUSTOMER],
					breadcrumbs: [{ title: "Phân cấp", name: "customer_group_first.index" }],
					gtm: "Trang Phân cấp"
				}
			},
			{
				path: "customer-group-first/create",
				name: "customer_group_first.create",
				component: page("customer/customer_group_first/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Quản lý đối tác",
					permissions: [PERMISSIONS.ADD_CUSTOMER],
					breadcrumbs: [
						{ title: "Quản lý đối tác", name: "customer_group_first.index" },
						{ title: "Phân cấp", name: "customer_group_first.create" }
					],
					gtm: "Trang Phân cấp"
				}
			},
			{
				path: "customer-group-first/edit",
				name: "customer_group_first.edit",
				component: page("customer/customer_group_first/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa phân cấp 1",
					permissions: [PERMISSIONS.EDIT_CUSTOMER],
					breadcrumbs: [
						{ title: "Phân cấp 1", name: "customer_group_first.index" },
						{ title: "Chỉnh sửa", name: "customer_group_first.edit" }
					],
					gtm: "Trang chỉnh sửa phân cấp 1"
				}
			},
			// second
			{
				path: "customer-group-second",
				name: "customer_group_second.index",
				component: page("customer/customer_group_second/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Phân cấp 2",
					permissions: [PERMISSIONS.VIEW_CUSTOMER],
					breadcrumbs: [{ title: "Phân cấp 2", name: "customer_group_second.index" }],
					gtm: "Trang Phân cấp 2"
				}
			},
			{
				path: "customer-group-second/create",
				name: "customer_group_second.create",
				component: page("customer/customer_group_second/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo Phân cấp 2",
					permissions: [PERMISSIONS.ADD_CUSTOMER],
					breadcrumbs: [
						{ title: "Phân cấp 2", name: "customer_group_second.index" },
						{ title: "Tạo mới", name: "customer_group_second.create" }
					],
					gtm: "Trang tạo phân cấp 2"
				}
			},
			{
				path: "customer-group-second/edit",
				name: "customer_group_second.edit",
				component: page("customer/customer_group_second/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa phân cấp 2",
					permissions: [PERMISSIONS.EDIT_CUSTOMER],
					breadcrumbs: [
						{ title: "Phân cấp 2", name: "customer_group_second.index" },
						{ title: "Chỉnh sửa", name: "customer_group_second.edit" }
					],
					gtm: "Trang chỉnh sửa phân cấp 2"
				}
			},
			// third
			{
				path: "customer-group-third",
				name: "customer_group_third.index",
				component: page("customer/customer_group_third/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Phân cấp 3",
					permissions: [PERMISSIONS.VIEW_CUSTOMER],
					breadcrumbs: [{ title: "Phân cấp 3", name: "customer_group_third.index" }],
					gtm: "Trang Phân cấp 3"
				}
			},
			{
				path: "customer-group-third/create",
				name: "customer_group_third.create",
				component: page("customer/customer_group_third/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới phân cấp 3",
					permissions: [PERMISSIONS.ADD_CUSTOMER],
					breadcrumbs: [
						{ title: "Phân cấp 3", name: "customer_group_third.index" },
						{ title: "Tạo mới", name: "customer_group_third.create" }
					],
					gtm: "Trang Phân cấp 3"
				}
			},
			{
				path: "customer-group-third/edit",
				name: "customer_group_third.edit",
				component: page("customer/customer_group_third/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa phân cấp 3",
					permissions: [PERMISSIONS.EDIT_CUSTOMER],
					breadcrumbs: [
						{ title: "Phân cấp 3", name: "customer_group_third.index" },
						{ title: "Chỉnh sửa", name: "customer_group_third.edit" }
					],
					gtm: "Trang chỉnh sửa phân cấp 3"
				}
			},
			// fourth
			{
				path: "customer-group-fourth",
				name: "customer_group_fourth.index",
				component: page("customer/customer_group_fourth/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Phân cấp 4",
					permissions: [PERMISSIONS.VIEW_CUSTOMER],
					breadcrumbs: [{ title: "Phân cấp 4", name: "customer_group_fourth.index" }],
					gtm: "Trang Phân cấp 4"
				}
			},
			{
				path: "customer-group-fourth/create",
				name: "customer_group_fourth.create",
				component: page("customer/customer_group_fourth/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới phân cấp 4",
					permissions: [PERMISSIONS.ADD_CUSTOMER],
					breadcrumbs: [
						{ title: "Phân cấp 4", name: "customer_group_fourth.index" },
						{ title: "Tạo mới", name: "customer_group_fourth.create" }
					],
					gtm: "Trang tạo mới phân cấp 4"
				}
			},
			{
				path: "customer-group-fourth/edit",
				name: "customer_group_fourth.edit",
				component: page("customer/customer_group_fourth/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa Phường/Xã",
					permissions: [PERMISSIONS.EDIT_CUSTOMER],
					breadcrumbs: [
						{ title: "Phân cấp 4", name: "customer_group_fourth.index" },
						{ title: "Chỉnh sửa", name: "customer_group_fourth.edit" }
					],
					gtm: "Trang chỉnh sửa phân cấp 4"
				}
			},
		]
	},
	// price-estimate
	{
		path: "/price_estimate",
		name: "price_estimate.index",
		component: page("price_estimate/Index.vue"),
		beforeEnter: ResolveGuard([AuthGuard]),
		meta: {
			permissions: [PERMISSIONS.VIEW_PRICE],
			gtm: "Trang định giá sơ bộ"
		}
	},
	{
		path: "/price_estimate/get_log",
		name: "price_estimate.log",
		component: page("price_estimate/GetLog.vue"),
		beforeEnter: ResolveGuard([AuthGuard]),
		meta: {
			permissions: [PERMISSIONS.VIEW_PRICE],
			gtm: "Trang lịch sử định giá sơ bộ"
		}
	},

	// staff
	{
		path: "/manage",
		component: Resource,
		children: [
			{
				path: "staff",
				name: "staff.index",
				component: page("staff/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tài khoản",
					permissions: [PERMISSIONS.ADD_ROLE], // Force need role permision
					breadcrumbs: [{ title: "Quản lý nội bộ" }, { title: "Tài khoản", name: "staff.index" }],
					gtm: "Trang Tài khoản"
				}
			},
			{
				path: "staff/create",
				name: "staff.create",
				component: page("staff/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tài khoản",
					permissions: [PERMISSIONS.ADD_ROLE], // Force need role permision
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{ title: "Tài khoản", name: "staff.index" },
						{ title: "Tạo Tài khoản", name: "staff.create" }
					],
					gtm: "Trang tạo Tài khoản"
				}
			},
			{
				path: "staff/edit",
				name: "staff.edit",
				component: page("staff/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa tài khoản",
					permissions: [PERMISSIONS.EDIT_ROLE], // Force need role permision
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{ title: "Tài khoản", name: "staff.index" },
						{ title: "Chỉnh sửa tài khoản", name: "staff.edit" }
					],
					gtm: "Trang chỉnh sửa Tài khoản"
				}
			},
			// role
			{
				path: "role",
				name: "role.index",
				component: page("role/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Phân quyền",
					permissions: [PERMISSIONS.VIEW_ROLE],
					breadcrumbs: [{ title: "Quản lý nội bộ" }, { title: "Phân quyền", name: "role.index" }],
					gtm: "Trang phân quyền"
				}
			},
			{
				path: "role/create",
				name: "role.create",
				component: page("role/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo phân quyền",
					permissions: [PERMISSIONS.ADD_ROLE],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{ title: "Phân quyền", name: "role.index" },
						{ title: "Tạo phân quyền", name: "role.create" }
					],
					gtm: "Trang tạo phân quyền"
				}
			},
			{
				path: "role/edit",
				name: "role.edit",
				component: page("role/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa phân quyền",
					permissions: [PERMISSIONS.EDIT_ROLE],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{ title: "Phân quyền", name: "role.index" },
						{ title: "Chỉnh sửa phân quyền", name: "role.edit" }
					],
					gtm: "Trang chỉnh sửa phân quyền"
				}
			}
		]
	},

	// Category
	{
		path: "/category",
		component: Resource,
		children: [
			// unit price
			{
				path: "unit-price",
				name: "unit_price.index",
				component: page("category/unit_price/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Đơn giá UBND",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{ title: "Đơn giá UBND", name: "unit_price.index" }],
					gtm: "Trang đơn giá UBND"
				}
			},
			{
				path: "unit-price/detail",
				name: "unit_price.detail",
				component: page("category/unit_price/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Đơn giá UBND chi tiết",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [
						{ title: "Đơn giá UBND", name: "unit_price.index" },
						{
							title: "Đơn giá UBND chi tiết",
							name: "unit_price.detail"
						}
					],
					gtm: "Trang đơn giá UBND chi tiết"
				}
			},
			// distance
			{
				path: "distance",
				name: "distance.index",
				component: page("category/distance/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Đoạn đường",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					}, { title: "Đoạn đường", name: "distance.index" }],
					gtm: "Trang đoạn đường"
				}
			},
			{
				path: "distance/create",
				name: "distance.create",
				component: page("category/distance/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thêm đoạn đường",
					permissions: [PERMISSIONS.ADD_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Đoạn đường", name: "distance.index" },
					{ title: "Tạo đoạn đường", name: "distance.create" }
					],
					gtm: "Trang thêm đoạn đường"
				}
			},
			{
				path: "distance/edit",
				name: "distance.edit",
				component: page("category/distance/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa đoạn đường",
					permissions: [PERMISSIONS.EDIT_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Đoạn đường", name: "distance.index" },
					{ title: "Chỉnh sửa đoạn đường", name: "distance.edit" }
					],
					gtm: "Trang chỉnh sửa đoạn đường"
				}
			},
			// street
			{
				path: "street",
				name: "street.index",
				component: page("category/street/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Đường phố",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					}, { title: "Đường phố", name: "street.index" }],
					gtm: "Trang đường phố"
				}
			},
			{
				path: "street/create",
				name: "street.create",
				component: page("category/street/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thêm đường phố",
					permissions: [PERMISSIONS.ADD_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Đường phố", name: "street.index" },
					{ title: "Tạo đường phố", name: "street.create" }
					],
					gtm: "Trang thêm đường phố"
				}
			},
			{
				path: "street/edit",
				name: "street.edit",
				component: page("category/street/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa đường phố",
					permissions: [PERMISSIONS.EDIT_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Đường phố", name: "street.index" },
					{ title: "Chỉnh sửa đường phố", name: "street.edit" }
					],
					gtm: "Trang chỉnh sửa đường phố"
				}
			},
			{
				path: "province",
				name: "province.index",
				component: page("category/province/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tỉnh/Thành",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					}, { title: "Tỉnh/Thành", name: "province.index" }],
					gtm: "Trang tỉnh thành"
				}
			},
			{
				path: "province/create",
				name: "province.create",
				component: page("category/province/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo Tỉnh/Thành",
					permissions: [PERMISSIONS.ADD_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Tỉnh/Thành", name: "province.index" },
					{ title: "Tạo Tỉnh/Thành", name: "province.create" }
					],
					gtm: "Trang tạo tỉnh thành"
				}
			},
			{
				path: "province/edit",
				name: "province.edit",
				component: page("category/province/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa Tỉnh/Thành",
					permissions: [PERMISSIONS.EDIT_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Tỉnh/Thành", name: "province.index" },
					{ title: "Chỉnh sửa Tỉnh/Thành", name: "province.edit" }
					],
					gtm: "Trang chỉnh sửa tỉnh thành"
				}
			},
			// district
			{
				path: "district",
				name: "district.index",
				component: page("category/district/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Quận/Huyện",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					}, { title: "Quận/Huyện", name: "district.index" }],
					gtm: "Trang quận huyện"
				}
			},
			{
				path: "district/create",
				name: "district.create",
				component: page("category/district/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo Quận/Huyện",
					permissions: [PERMISSIONS.ADD_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Quận/Huyện", name: "district.index" },
					{ title: "Tạo Quận/Huyện", name: "district.create" }
					],
					gtm: "Trang tạo quận huyện"
				}
			},
			{
				path: "district/edit",
				name: "district.edit",
				component: page("category/district/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa Quận/Huyện",
					permissions: [PERMISSIONS.EDIT_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Quận/Huyện", name: "district.index" },
					{ title: "Chỉnh sửa Quận/Huyện", name: "district.edit" }
					],
					gtm: "Trang chỉnh sửa quận huyện"
				}
			},
			// ward
			{
				path: "ward",
				name: "ward.index",
				component: page("category/ward/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Phường/Xã",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					}, { title: "Phường/Xã", name: "ward.index" }],
					gtm: "Trang phường/xã"
				}
			},
			{
				path: "ward/create",
				name: "ward.create",
				component: page("category/ward/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo Phường/Xã",
					permissions: [PERMISSIONS.ADD_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Phường/Xã", name: "ward.index" },
					{ title: "Tạo Phường/Xã", name: "ward.create" }
					],
					gtm: "Trang tạo phường/xã"
				}
			},
			{
				path: "ward/edit",
				name: "ward.edit",
				component: page("category/ward/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa Phường/Xã",
					permissions: [PERMISSIONS.EDIT_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Phường/Xã", name: "ward.index" },
					{ title: "Chỉnh sửa Phường/Xã", name: "ward.edit" }
					],
					gtm: "Trang chỉnh sửa phường/xã"
				}
			},
			// branch
			{
				path: "branch",
				name: "branch.index",
				component: page("category/branch/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi nhánh",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{ title: "Quản lý nội bộ" }, { title: "Chi nhánh", name: "branch.index" }],
					gtm: "Trang chi nhánh"
				}
			},
			{
				path: "branch/create",
				name: "branch.create",
				component: page("category/branch/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo chi nhánh",
					permissions: [PERMISSIONS.ADD_CATEGORY],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{ title: "Chi nhánh", name: "branch.index" },
						{ title: "Tạo chi nhánh", name: "branch.create" }
					],
					gtm: "Trang tạo chi nhánh"
				}
			},
			{
				path: "branch/edit",
				name: "branch.edit",
				component: page("category/branch/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa chi nhánh",
					permissions: [PERMISSIONS.EDIT_CATEGORY],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{ title: "Chi nhánh", name: "branch.index" },
						{ title: "Chỉnh sửa chi nhánh", name: "branch.edit" }
					],
					gtm: "Trang chỉnh sửa chi nhánh"
				}
			},
			// apartment
			{
				path: "apartment",
				name: "apartment.index",
				component: page("category/apartment/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chung cư",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					}, { title: "Chung cư", name: "apartment.index" }],
					gtm: "Trang chung cư"
				}
			},
			{
				path: "apartment/create",
				name: "apartment.create",
				component: page("category/apartment/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo chung cư",
					permissions: [PERMISSIONS.ADD_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Chung cư", name: "apartment.index" },
					{ title: "Tạo chung cư", name: "apartment.create" }
					],
					gtm: "Trang tạo chung cư"
				}
			},
			{
				path: "apartment/edit",
				name: "apartment.edit",
				component: page("category/apartment/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa chung cư",
					permissions: [PERMISSIONS.EDIT_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					},
					{ title: "Chung cư", name: "apartment.index" },
					{ title: "Chỉnh sửa chung cư", name: "apartment.edit" }
					],
					gtm: "Trang chỉnh sửa chung cư"
				}
			},
			{
				path: "dictionary",
				name: "dictionary.index",
				component: page("category/dictionaries/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Danh mục",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					}, { title: "Trường dữ liệu", name: "dictionary.index" }],
					gtm: "Trang danh mục"
				}
			},
			{
				path: "dictionary/create",
				name: "dictionary.create",
				component: page("category/dictionaries/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thêm Danh mục",
					permissions: [PERMISSIONS.ADD_CATEGORY],
					breadcrumbs: [
						{ title: "Danh mục", name: "dictionary.index" },
						{ title: "Thêm danh mục", name: "dictionary.create" }
					],
					gtm: "Trang thêm danh mục"
				}
			},
			{
				path: "dictionary/edit",
				name: "dictionary.edit",
				component: page("category/dictionaries/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa danh mục",
					permissions: [PERMISSIONS.EDIT_CATEGORY],
					breadcrumbs: [
						{ title: "Danh mục", name: "dictionary.index" },
						{ title: "Chỉnh sửa danh mục", name: "dictionary.edit" }
					],
					gtm: "Trang chỉnh sửa danh mục"
				}
			},
			// building
			{
				path: "building",
				name: "building.index",
				component: page("category/building/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Công trình xây dựng",
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [
						{
							title: "Quản lý dữ liệu",
						},
						{ title: "Loại công trình", name: "building.index" }
					],
					gtm: "Trang công trình xây dựng"
				}
			},
			{
				path: "building/create",
				name: "building.create",
				component: page("category/building/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thêm Công trình xây dựng",
					permissions: [PERMISSIONS.ADD_CATEGORY],
					breadcrumbs: [
						{ title: "Công trình xây dựng", name: "building.index" },
						{
							title: "Thêm công trình xây dựng",
							name: "building.create"
						}
					],
					gtm: "Trang thêm công trình xây dựng"
				}
			},
			{
				path: "building/edit",
				name: "building.edit",
				component: page("category/building/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa công trình xây dựng",
					permissions: [PERMISSIONS.EDIT_CATEGORY],
					breadcrumbs: [
						{ title: "Công trình xây dựng", name: "building.index" },
						{
							title: "Chỉnh sửa công trình xây dựng",
							name: "building.edit"
						}
					],
					gtm: "Trang chỉnh sửa công trình xây dựng"
				}
			}
		]
	},

	// Appraise
	{
		path: "/appraise",
		component: Resource,
		children: [
			{
				path: "working_hours",
				name: "working_hours.index",
				component: page("appraise/working_hours/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thời gian làm việc",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{
							title: "Thời gian làm việc",
							name: "working_hours.index"
						}
					]
				},
				gtm: "Trang Thời gian làm việc"
			},
			{
				path: "pre_certificate_config",
				name: "pre_certificate_config.index",
				component: page("appraise/pre_certificate_config/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "SLA",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{
							title: "SLA",
							name: "pre_certificate_config.index"
						}
					]
				},
				gtm: "Trang Thời gian thực hiện"
			},
			// element
			{
				path: "element",
				name: "element.index",
				component: page("appraise/element/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Yếu tố so sánh",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [{
						title: "Quản lý dữ liệu",
					}, { title: "Yếu tố so sánh", name: "element.index" }]
				},
				gtm: "Trang yếu tố so sánh"
			},
			{
				path: "appraisal-construction",
				name: "appraisal-construction.index",
				component: page("appraise/appraisal_construction/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Khai báo CTXD thẩm định",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [
						{
							title: "Quản lý dữ liệu",
						},
						{
							title: "Công ty xây dựng",
							name: "appraisal-construction.index"
						}
					],
					gtm: "Trang khai báo CTXD thẩm định"
				}
			},
			{
				path: "appraise-other",
				name: "appraise-other.index",
				component: page("appraise/appraise_other/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Khai báo thông tin TDK",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [
						{
							title: "Quản lý dữ liệu",
						},
						{
							title: "Cơ sở thẩm định",
							name: "appraise-other.index"
						}
					],
					gtm: "Trang khai báo thông tin TDK"
				}
			},
			{
				path: "appraise-law",
				name: "appraise-law.index",
				component: page("appraise/appraise_law/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Khai báo văn bản pháp luật",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CATEGORY],
					breadcrumbs: [
						{
							title: "Quản lý dữ liệu",
						},
						{
							title: "Văn bản pháp luật",
							name: "appraise-law.index"
						}
					],
					gtm: "Trang khai báo VBPL"
				}
			},
			{
				path: "appraiser-company",
				name: "appraiser-company.index",
				component: page("appraise/appraiser_company/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thông tin công ty",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_ROLE],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{
							title: "Thông tin công ty",
							name: "appraiser-company.index"
						}
					],
					gtm: "Trang thông tin công ty"
				}
			},
			{
				path: "appraiser-company/create",
				name: "appraiser-company.create",
				component: page("appraise/appraiser_company/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thêm thông tin công ty",
					// fix_permission
					permissions: [PERMISSIONS.ADD_ROLE],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{
							title: "Thông tin công ty",
							name: "appraiser-company.index"
						},
						{
							title: "Thêm thông tin công ty",
							name: "appraiser-company.create"
						}
					],
					gtm: "Trang thêm thông tin công ty"
				}
			},
			{
				path: "appraiser-company/detail",
				name: "appraiser-company.detail",
				component: page("appraise/appraiser_company/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thông tin công ty",
					// fix_permission
					permissions: [PERMISSIONS.ADD_ROLE],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{
							title: "Thông tin công ty",
							name: "appraiser-company.index"
						},
						{
							title: "Chi tiết thông tin công ty",
							name: "appraiser-company.edit"
						}
					],
					gtm: "Trang thông tin công ty"
				}
			},
			{
				path: "appraiser-company/edit",
				name: "appraiser-company.edit",
				component: page("appraise/appraiser_company/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Cập nhật thông tin công ty",
					// fix_permission
					permissions: [PERMISSIONS.ADD_ROLE],
					breadcrumbs: [
						{ title: "Quản lý nội bộ" },
						{
							title: "Thông tin công ty",
							name: "appraiser-company.index"
						},
						{ title: 'Chỉnh sửa thông tin công ty', name: 'appraiser-company.edit' }
					],
					gtm: "Trang cập nhật thông tin công ty"
				}
			},
			{
				path: "document-config",
				name: "watermark.index",
				component: page("appraise/watermark/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Thông tin tài liệu",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_ROLE],
					breadcrumbs: [
						{ title: "Thông tin tài liệu", name: "watermark.index" }
					],
					gtm: "Trang thông tin tài liệu"
				}
			}
		]
	},
	{
		path: "/certification_asset",
		component: Resource,
		children: [
			{
				path: "/certification_asset/real-estate",
				name: "certification_asset.index",
				component: page("certification_asset/real_estate/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_asset.index"
						},
						{ title: "Bất động sản", name: "certification_asset.index" }
					],
					gtm: "Trang thẩm định tài sản"
				}
			},
			{
				path: "/certification_asset/personal-property",
				name: "certification_personal_property.index",
				component: page("certification_asset/personal_property/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "thẩm định tài sản",
					// denied: ['trial'],
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						}
					],
					gtm: "Trang thẩm định tài sản động sản"
				}
			},
			{
				path: "/certification_asset/real-estate/create",
				name: "certification_asset.create",
				component: page("certification_asset/real_estate/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới bất động sản",
					// fix_permission
					permissions: [PERMISSIONS.ADD_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_asset.index"
						},
						{
							title: "Bất động sản",
							name: "certification_asset.index"
						},
						{
							title: "Tạo mới bất động sản",
							name: "certification_asset.create"
						}
					],
					gtm: "Trang tạo mới bất động sản"
				}
			},
			{
				path: "/certification_asset/real-estate/detail",
				name: "certification_asset.detail",
				component: page("certification_asset/real_estate/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết bất động sản",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_asset.index"
						},
						{
							title: "Bất động sản",
							name: "certification_asset.index"
						},
						{
							title: "Chi tiết bất động sản",
							name: "certification_asset.detail"
						}
					],
					gtm: "Trang chi tiết bất động sản"
				}
			},
			{
				path: "/certification_asset/real-estate/edit",
				name: "certification_asset.edit",
				component: page("certification_asset/real_estate/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa bất động sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_asset.index"
						},
						{
							title: "Bất động sản",
							name: "certification_asset.index"
						},
						{
							title: "Chỉnh sửa bất động sản",
							name: "certification_asset.edit"
						}
					],
					gtm: "Trang chỉnh sửa bất động sản"
				}
			},
			// ------------------------------------------Apartment ------------------------------------------------------------------
			{
				path: "/certification_asset/apartment/create",
				name: "certification_asset.apartment.create",
				component: page("certification_asset/real_estate/apartment/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới bất động sản",
					// fix_permission
					permissions: [PERMISSIONS.ADD_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_asset.index"
						},
						{
							title: "Bất động sản",
							name: "certification_asset.index"
						},
						{
							title: "Tạo mới bất động sản",
							name: "certification_asset.apartment.create"
						}
					],
					gtm: "Trang tạo mới bất động sản"
				}
			},
			{
				path: "/certification_asset/apartment/edit",
				name: "certification_asset.apartment.edit",
				component: page("certification_asset/real_estate/apartment/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa bất động sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_asset.index"
						},
						{
							title: "Bất động sản",
							name: "certification_asset.index"
						},
						{
							title: "Chỉnh sửa bất động sản",
							name: "certification_asset.apartment.edit"
						}
					],
					gtm: "Trang chỉnh sửa bất động sản"
				}
			},
			{
				path: "/certification_asset/apartment/detail",
				name: "certification_asset.apartment.detail",
				component: page("certification_asset/real_estate/apartment/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết bất động sản",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_asset.index"
						},
						{
							title: "Bất động sản",
							name: "certification_asset.index"
						},
						{
							title: "Chi tiết bất động sản",
							name: "certification_asset.apartment.detail"
						}
					],
					gtm: "Trang chi tiết bất động sản"
				}
			},
			// ----------------------------------------Other-Purpose ----------------------------------------------------------------
			{
				path: "/certification_asset/other-purpose/create",
				name: "certification_asset.other_purpose.create",
				component: page(
					"certification_asset/personal_property/other_purpose_form/Create.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Tạo mới động sản khác",
							name: "certification_asset.other_purpose.create"
						}
					],
					gtm: "Trang chỉnh sửa TSTĐ ĐS khác"
				}
			},
			{
				path: "/certification_asset/other-purpose/edit",
				name: "certification_asset.other_purpose.edit",
				component: page(
					"certification_asset/personal_property/other_purpose_form/Edit.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Chỉnh sửa động sản khác",
							name: "certification_asset.other_purpose.edit"
						}
					],
					gtm: "Trang chỉnh sửa TSTĐ ĐS khác"
				}
			},
			{
				path: "/certification_asset/other-purpose/detail",
				name: "certification_asset.other_purpose.detail",
				component: page(
					"certification_asset/personal_property/other_purpose_form/Detail.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Chi tiết động sản khác",
							name: "certification_asset.other_purpose.detail"
						}
					],
					gtm: "Trang chi tiết TSTĐ ĐS khác"
				}
			},
			// ---------------------------------------- vehicle ----------------------------------------------------------------
			{
				path: "/certification_asset/vehicle/create",
				name: "certification_asset.vehicle.create",
				component: page(
					"certification_asset/personal_property/vehicle_form/Create.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Tạo mới phương tiện vận tải",
							name: "certification_asset.vehicle.create"
						}
					],
					gtm: "Trang tạo mới TSTĐ ĐS PTVT"
				}
			},
			{
				path: "/certification_asset/vehicle/edit",
				name: "certification_asset.vehicle.edit",
				component: page(
					"certification_asset/personal_property/vehicle_form/Edit.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Chỉnh sửa phương tiện vận tải",
							name: "certification_asset.vehicle.edit"
						}
					],
					gtm: "Trang chỉnh sửa TSTĐ ĐS PTVT"
				}
			},
			{
				path: "/certification_asset/vehicle/detail",
				name: "certification_asset.vehicle.detail",
				component: page(
					"certification_asset/personal_property/vehicle_form/Detail.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Chi tiết phương tiện vận tải",
							name: "certification_asset.vehicle.detail"
						}
					],
					gtm: "Trang chi tiết TSTĐ ĐS PTVT"
				}
			},
			// ---------------------------------------- Machine ----------------------------------------------------------------
			{
				path: "/certification_asset/machine/create",
				name: "certification_asset.machine.create",
				component: page(
					"certification_asset/personal_property/machine_form/Create.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Thêm mới máy móc thiết bị",
							name: "certification_asset.machine.create"
						}
					],
					gtm: "Trang tạo mới TSTĐ ĐS MMTB"
				}
			},
			{
				path: "/certification_asset/machine/edit",
				name: "certification_asset.machine.edit",
				component: page(
					"certification_asset/personal_property/machine_form/Edit.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Chỉnh sửa máy móc thiết bị",
							name: "certification_asset.machine.edit"
						}
					],
					gtm: "Trang chỉnh sửa TSTĐ ĐS MMTB"
				}
			},
			{
				path: "/certification_asset/machine/detail",
				name: "certification_asset.machine.detail",
				component: page(
					"certification_asset/personal_property/machine_form/Detail.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Chi tiết máy móc thiết bị",
							name: "certification_asset.machine.edit"
						}
					],
					gtm: "Trang chi tiết TSTĐ ĐS MMTB"
				}
			},
			// ---------------------------------------- New Persional Property ----------------------------------------------------------------
			{
				path: "/certification_asset/personal_property/create",
				name: "certification_asset.personal_property.create",
				component: page(
					"certification_asset/personal_property/personal_property_new/Create.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Tạo mới động sản",
							name: "certification_asset.personal_property.create"
						}
					],
					gtm: "Trang tạo mới TSTĐ ĐS"
				}
			},
			{
				path: "/certification_asset/personal_property/edit",
				name: "certification_asset.personal_property.edit",
				component: page(
					"certification_asset/personal_property/personal_property_new/Edit.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Chỉnh sửa động sản",
							name: "certification_asset.personal_property.edit"
						}
					],
					gtm: "Trang chỉnh sửa TSTĐ ĐS"
				}
			},
			{
				path: "/certification_asset/personal_property/detail",
				name: "certification_asset.personal_property.detail",
				component: page(
					"certification_asset/personal_property/personal_property_new/Detail.vue"
				),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết thẩm định tài sản",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_ASSET],
					breadcrumbs: [
						{
							title: "thẩm định tài sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Động sản",
							name: "certification_personal_property.index"
						},
						{
							title: "Chi tiết động sản",
							name: "certification_asset.machine.edit"
						}
					],
					gtm: "Trang chi tiết TSTĐ ĐS MMTB"
				}
			}
		]
	},

	// certificate
	// {
	// 	path: '/certificate',
	// 	component: Resource,
	// 	children: [{
	// 		path: '',
	// 		name: 'certificate.index',
	// 		component: page('certificate/Index.vue'),
	// 		beforeEnter: ResolveGuard([AuthGuard]),
	// 		meta: {
	// 			title: 'thẩm định tài sản',
	// 			// fix_permission
	// 			permissions: [PERMISSIONS.VIEW_PRICE],
	// 			breadcrumbs: [
	// 				{ title: 'thẩm định tài sản', name: 'certificate.index' }
	// 			]
	// 		}
	// 	},
	// 	{
	// 		path: '/certificate/create',
	// 		name: 'certificate.create',
	// 		component: page('certificate/Create.vue'),
	// 		beforeEnter: ResolveGuard([AuthGuard]),
	// 		meta: {
	// 			title: 'Tạo mới thẩm định tài sản',
	// 			// fix_permission
	// 			permissions: [PERMISSIONS.ADD_PRICE],
	// 			breadcrumbs: [
	// 				{ title: 'thẩm định tài sản', name: 'certificate.index' },
	// 				{ title: 'Tạo mới thẩm định tài sản', name: 'certificate.create' }
	// 			]
	// 		}
	// 	},
	// 	{
	// 		path: '/certificate/detail',
	// 		name: 'certificate.detail',
	// 		component: page('certificate/Detail.vue'),
	// 		beforeEnter: ResolveGuard([AuthGuard]),
	// 		meta: {
	// 			title: 'Chi tiết thẩm định tài sản',
	// 			// fix_permission
	// 			permissions: [PERMISSIONS.VIEW_PRICE],
	// 			breadcrumbs: [
	// 				{ title: 'thẩm định tài sản', name: 'certificate.index' },
	// 				{ title: 'Chi tiết thẩm định tài sản', name: 'certificate.detail' }
	// 			]
	// 		}
	// 	},
	// 	{
	// 		path: '/certificate/edit',
	// 		name: 'certificate.edit',
	// 		component: page('certificate/Edit.vue'),
	// 		beforeEnter: ResolveGuard([AuthGuard]),
	// 		meta: {
	// 			title: 'Chỉnh sửa thẩm định tài sản',
	// 			// fix_permission
	// 			permissions: [PERMISSIONS.EDIT_PRICE],
	// 			breadcrumbs: [
	// 				{ title: 'thẩm định tài sản', name: 'certificate.index' },
	// 				{ title: 'Chỉnh sửa thẩm định tài sản', name: 'certificate.edit' }
	// 			]
	// 		}
	// 	}
	// 	]
	// },
	// appraisal
	{
		path: "/appraisal",
		component: Resource,
		children: [
			{
				path: "",
				name: "appraisal.index",
				component: page("appraisal/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Danh sách hồ sơ thẩm định",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_PRICE],
					breadcrumbs: [{ title: "Danh sách hồ sơ thẩm định", name: "appraisal.index" }],
					gtm: "Trang HSTĐ"
				}
			},
			{
				path: "/appraisal/create",
				name: "appraisal.create",
				component: page("appraisal/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới hồ sơ thẩm định",
					// fix_permission
					permissions: [PERMISSIONS.ADD_PRICE],
					breadcrumbs: [
						{ title: "Danh sách hồ sơ thẩm định", name: "appraisal.index" },
						{
							title: "Tạo mới hồ sơ thẩm định",
							name: "appraisal.create"
						}
					],
					gtm: "Trang tạo mới HSTĐ"
				}
			},
			{
				path: "/appraisal/detail",
				name: "appraisal.detail",
				component: page("appraisal/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết hồ sơ thẩm định",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_PRICE],
					breadcrumbs: [
						{ title: "Danh sách hồ sơ thẩm định", name: "appraisal.index" },
						{
							title: "Chi tiết hồ sơ thẩm định",
							name: "appraisal.detail"
						}
					],
					gtm: "Trang chi tiết HSTĐ"
				}
			},
			{
				path: "/appraisal/edit",
				name: "appraisal.edit",
				component: page("appraisal/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa hồ sơ thẩm định",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_PRICE],
					breadcrumbs: [
						{ title: "Danh sách hồ sơ thẩm định", name: "appraisal.index" },
						{
							title: "Chỉnh sửa hồ sơ thẩm định",
							name: "appraisal.edit"
						}
					],
					gtm: "Trang chỉnh sửa HSTĐ"
				}
			}
		]
	},
	// certification_brief
	{
		path: "/certification_brief",
		component: Resource,
		children: [
			{
				path: "",
				name: "certification_brief.index",
				component: page("certification_brief/Index2.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Danh sách hồ sơ thẩm định",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_BRIEF],
					breadcrumbs: [
						{
							title: "Danh sách hồ sơ thẩm định",
							name: "certification_brief.index"
						}
					],
					gtm: "Trang HSTĐ"
				}
			},
			{
				path: "/certification_brief/create",
				name: "certification_brief.create",
				component: page("certification_brief/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới hồ sơ thẩm định",
					// fix_permission
					permissions: [PERMISSIONS.ADD_CERTIFICATE_BRIEF],
					breadcrumbs: [
						{
							title: "Danh sách hồ sơ thẩm định",
							name: "certification_brief.index"
						},
						{
							title: "Tạo mới hồ sơ thẩm định",
							name: "certification_brief.create"
						}
					],
					gtm: "Trang tạo mới HSTĐ"
				}
			},
			{
				path: "/certification_brief/detail",
				name: "certification_brief.detail",
				component: page("certification_brief/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết hồ sơ thẩm định",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_CERTIFICATE_BRIEF],
					breadcrumbs: [
						{
							title: "Danh sách hồ sơ thẩm định",
							name: "certification_brief.index"
						},
						{
							title: "Chi tiết hồ sơ thẩm định",
							name: "certification_brief.detail"
						}
					],
					gtm: "Trang chi tiết HSTĐ"
				}
			},
			{
				path: "/certification_brief/edit",
				name: "certification_brief.edit",
				component: page("certification_brief/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chỉnh sửa hồ sơ thẩm định",
					// fix_permission
					permissions: [PERMISSIONS.EDIT_CERTIFICATE_BRIEF],
					breadcrumbs: [
						{
							title: "Danh sách hồ sơ thẩm định",
							name: "certification_brief.index"
						},
						{
							title: "Chỉnh sửa hồ sơ thẩm định",
							name: "certification_brief.edit"
						}
					],
					gtm: "Trang chỉnh sửa HSTĐ"
				}
			}
		]
	},

	// pre_certification
	{
		path: "/pre_certification",
		component: Resource,
		children: [
			{
				path: "/pre_certification/create",
				name: "pre_certification.create",
				component: page("pre_certification/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới yêu cầu sơ bộ",
					permissions: [PERMISSIONS.ADD_PRE_CERTIFICATE],
					breadcrumbs: [
						{
							title: "Danh sách yêu cầu sơ bộ",
							name: "pre_certification.index"
						},
						{
							title: "Tạo mới yêu cầu sơ bộ",
							name: "pre_certification.create"
						}
					],
					gtm: "Trang tạo mới YCSB"
				}
			},
			{
				path: "/pre_certification/edit",
				name: "pre_certification.edit",
				component: page("pre_certification/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				props: true,
				meta: {
					title: "Chỉnh sửa yêu cầu sơ bộ",
					permissions: [PERMISSIONS.EDIT_PRE_CERTIFICATE],
					breadcrumbs: [
						{
							title: "Danh sách yêu cầu sơ bộ",
							name: "pre_certification.index"
						},
						{
							title: "Chỉnh sửa yêu cầu sơ bộ",
							name: "pre_certification.edit"
						}
					],
					gtm: "Trang chỉnh sửa YCSB"
				}
			},
			{
				path: "",
				name: "pre_certification.index",
				component: page("pre_certification/Index2.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Danh sách yêu cầu sơ bộ",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_PRE_CERTIFICATE],
					breadcrumbs: [
						{
							title: "Danh sách yêu cầu sơ bộ",
							name: "pre_certification.index"
						}
					],
					gtm: "Trang YCSB"
				}
			},

			{
				path: "/pre_certification/detail",
				name: "pre_certification.detail",
				component: page("pre_certification/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết yêu cầu sơ bộ",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_PRE_CERTIFICATE],
					breadcrumbs: [
						{
							title: "Danh sách yêu cầu sơ bộ",
							name: "pre_certification.index"
						},
						{
							title: "Chi tiết yêu cầu sơ bộ",
							name: "pre_certification.detail"
						}
					],
					gtm: "Trang chi tiết YCSB"
				}
			}
		]
	},
	// dashboard
	{
		path: "/dashboard",
		component: Resource,
		children: [
			{
				path: "",
				name: "dashboard.index",
				component: page("dashboard/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Báo cáo quản trị",
					permissions: [PERMISSIONS.VIEW_DASHBOARD],
					breadcrumbs: [
						{
							title: "Báo cáo quản trị",
							name: "dashboard.index"
						}
					],
					gtm: "Trang Dashboard"
				}
			},
			// {
			// 	path: "/dashboard/general",
			// 	name: "dashboard.index2",
			// 	component: page("dashboard/Index2.vue"),
			// 	beforeEnter: ResolveGuard([AuthGuard]),
			// 	meta: {
			// 		title: "Bảng thông tin tổng hợp mới",
			// 		permissions: [PERMISSIONS.VIEW_DASHBOARD],
			// 		breadcrumbs: [
			// 			{
			// 				title: "Bảng thông tin tổng hợp mới",
			// 				name: "dashboard.index2"
			// 			}
			// 		],
			// 		gtm: "Trang Dashboard Mới"
			// 	}
			// }
		]
	},
	// Tra cứu chứng thư
	{
		path: "/tra-cuu-chung-thu",
		name: "certificate_lookup",
		component: page("certificate_lookup/Index.vue"),
		meta: { layout: "certificate_lookup", gtm: "Tra cứu chứng thư" }
	},
	// Error
	{
		path: "/error",
		component: Resource,
		children: [
			{
				path: "/403",
				name: "error.403",
				component: page("error/Error403.vue"),
				meta: {
					title: "",
					// permissions: PERMISSIONS.ALL,
					breadcrumbs: [{ title: "error", name: "error.403" }],
					gtm: "Trang lỗi 403"
				}
			},
			{
				path: "/404",
				name: "error.404",
				component: page("error/Error404.vue"),
				meta: {
					title: "",
					// permissions: PERMISSIONS.ALL,
					breadcrumbs: [{ title: "error", name: "error.404" }],
					gtm: "Trang lỗi 404"
				}
			},
			{
				path: "/409",
				name: "error.409",
				component: page("error/Error409.vue"),
				meta: {
					title: "",
					// permissions: PERMISSIONS.ALL,
					breadcrumbs: [{ title: "error", name: "error.409" }],
					gtm: "Trang lỗi 409"
				}
			},
			{
				path: "/429",
				name: "error.429",
				component: page("error/Error429.vue"),
				meta: {
					title: "",
					// permissions: PERMISSIONS.ALL,
					breadcrumbs: [{ title: "error", name: "error.429" }],
					gtm: "Trang lỗi 429"
				}
			},
			{
				path: "/500",
				name: "error.500",
				component: page("error/Error500.vue"),
				meta: {
					title: "",
					// permissions: PERMISSIONS.ALL,
					breadcrumbs: [{ title: "error", name: "error.500" }],
					gtm: "Trang lỗi 500"
				}
			},
			{
				path: "/503",
				name: "error.503",
				component: page("error/Error503.vue"),
				meta: {
					title: "",
					// permissions: PERMISSIONS.ALL,
					breadcrumbs: [{ title: "error", name: "error.503" }],
					gtm: "Trang lỗi 503"
				}
			}
		]
	},

	// Page not found
	{
		path: "*",
		component: page("error/Error404.vue"),
		meta: {
			gtm: "Trang lỗi 404"
		}
	},

	{
		path: "/",
		component: Resource,
		children: [
			{
				path: "",
				name: "page-not-found",
				component: page("PageNotFound.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Không tìm thấy trang",
					permissions: [PERMISSIONS.VIEW_DASHBOARD],
					breadcrumbs: [
						//   { title: 'Bất động sản' }
					],
					gtm: "Trang lỗi không tìm thấy trang"
				}
			}
		]
	},
	// price_estimates
	{
		path: "/price_estimates",
		component: Resource,
		children: [
			{
				path: "/price_estimates/create",
				name: "price_estimates.create",
				component: page("price_estimates/Create.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Tạo mới định giá sơ bộ",
					permissions: [PERMISSIONS.ADD_PRELIMINARY_ASSET],
					breadcrumbs: [
						{
							title: "định giá sơ bộ",
							name: "price_estimates.index"
						},
						{
							title: "Tạo mới định giá sơ bộ",
							name: "price_estimates.create"
						}
					],
					gtm: "Trang tạo mới TSSB"
				}
			},
			{
				path: "/price_estimates/edit",
				name: "price_estimates.edit",
				component: page("price_estimates/Edit.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				props: true,
				meta: {
					title: "Chỉnh sửa định giá sơ bộ",
					permissions: [PERMISSIONS.EDIT_PRELIMINARY_ASSET],
					breadcrumbs: [
						{
							title: "định giá sơ bộ",
							name: "price_estimates.index"
						},
						{
							title: "Chỉnh sửa định giá sơ bộ",
							name: "price_estimates.edit"
						}
					],
					gtm: "Trang chỉnh sửa TSSB"
				}
			},

			{
				path: "/price_estimates/index",
				name: "price_estimates.index",
				component: page("price_estimates/Index.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Định giá sơ bộ",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_PRELIMINARY_ASSET],
					breadcrumbs: [
						{
							title: "định giá sơ bộ",
							name: "price_estimates.index"
						}
					],
					gtm: "Trang TSSB"
				}
			},
			{
				path: "/price_estimates/detail",
				name: "price_estimates.detail",
				component: page("price_estimates/Detail.vue"),
				beforeEnter: ResolveGuard([AuthGuard]),
				meta: {
					title: "Chi tiết định giá sơ bộ",
					// fix_permission
					permissions: [PERMISSIONS.VIEW_PRELIMINARY_ASSET],
					breadcrumbs: [
						{
							title: "định giá sơ bộ",
							name: "price_estimates.index"
						},
						{
							title: "Chi tiết định giá sơ bộ",
							name: "price_estimates.detail"
						}
					],
					gtm: "Trang chi tiết TSSB"
				}
			}
		]
	},
	{
		path: "/instant-value",
		component: Resource,
		children: [
			{
				path: "/instant-value/check-value",
				name: "instant_value.check_value",
				component: page("instant_value/CheckValue.vue"),
				meta: {
					title: "Định giá trực tuyến",
					gtm: "Định giá trực tuyến"
				}
			}
		]
	}
];
