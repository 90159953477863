import Vue from "vue";
import "ant-design-vue/dist/antd.css";

import {
	Card,
	Table,
	Tooltip,
	Popconfirm,
	Dropdown,
	Divider,
	Menu,
	Icon,
	Button,
	Select,
	AutoComplete,
	Input,
	InputNumber,
	Switch,
	DatePicker,
	Modal,
	Empty,
	Collapse,
	notification,
	message,
	Progress,
	Pagination,
	Popover,
	Spin,
	Transfer,
	Radio,
	Checkbox,
	Slider,
	Drawer,
	Timeline,
	Avatar,
	Badge
} from "ant-design-vue";

Vue.use(Divider);
Vue.use(AutoComplete);
Vue.use(Card);
Vue.use(Table);
Vue.use(Tooltip);
Vue.use(Popconfirm);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Select);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Switch);
Vue.use(DatePicker);
Vue.use(Modal);
Vue.use(Empty);
Vue.use(Progress);
Vue.use(Pagination);
Vue.use(Collapse);
Vue.use(Popover);
Vue.use(Spin);
Vue.use(Transfer);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Drawer);
Vue.use(Timeline);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Slider);
Vue.use(Avatar);
Vue.use(Badge);
Vue.prototype.$notification = notification;
Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
