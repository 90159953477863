let navigationsHome = [];

const loadNavigations = async () => {
	const clientName = process.env.VUE_APP_CLIENT_NAME || 'Default';
	try {
		const config = await import(`@/config/navigationsHome/navigationsHome${clientName}.js`);
		navigationsHome = config.navigationsHome || [];
	} catch (error) {
		console.error('Error loading client config, falling back to default:', error);
	}
};

loadNavigations();

export { navigationsHome };